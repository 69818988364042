import { ReplaceStep } from "prosemirror-transform";

export enum SuggestionType {
  ADD = "ADD",
  REMOVE = "REMOVE",
  REPLACE = "REPLACE",
}

export enum SuggestionStatusType {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

export type TGuidanceSuggestion = {
  createdBy: {
    name: string;
  };
  createdAt: string;
  status: SuggestionStatusType;
  baseVersion: number;
  steps: ReplaceStep[];
  id: string;
};
