import React from "react";
import { FormattedDiffText } from "../utils/DiffUtils";
export default function PincitesDiffView({
  oldText,
  newText,
}: {
  oldText: string;
  newText: string;
}): React.JSX.Element {
  const diffedHTML = FormattedDiffText(oldText, newText);
  return <div className="template-text-container template-text-container-diff">{diffedHTML}</div>;
}
