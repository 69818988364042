import React from "react";

export type TShowErrorDialog = (errorData: {
  errorMsg: string;
  error: Error;
  token?: string;
  skipLogging?: boolean;
  showRescanButton?: boolean;
}) => void;

interface ErrorHandlingContextProps {
  showErrorDialog: TShowErrorDialog;
}

const defaultErrorHandlingContextValue: ErrorHandlingContextProps = {
  showErrorDialog: () => {},
};

const ErrorHandlingContext = React.createContext(defaultErrorHandlingContextValue);

export default ErrorHandlingContext;
