import React from "react";
import { Stack, ICommandBarItemProps, CommandBar } from "@fluentui/react";
import { useAuthToken } from "../auth/useAuthToken";
import Home from "./Home";
import { AuthProvider } from "../auth/AuthProvider";
import ErrorHandlingProvider from "../error_handling/ErrorHandlingProvider";
import AnalyzedDocumentResults from "./AnalyzedDocumentResults";
import ChoosePlaybookModal from "./ChoosePlaybookModal";
import { initializeSentry } from "../logging/Sentry";
import * as Sentry from "@sentry/react";
import { logEvent } from "../logging/AzureApplicationInsights";
import FeedbackProvider from "../feedback/FeedbackProvider";
import useFeedbackModal from "../feedback/useFeedbackModal";
import { TSignIn } from "../auth/AuthContext";
import AnalyzeContractProvider from "../context/analyze_contract/AnalyzeContractProvider";
import useAnalyzeContract from "../context/analyze_contract/useAnalyzeContract";
import SuggestedChangesProvider from "../context/suggested_changes/SuggestedChangesProvider";
import ClauseProvider from "../context/clause_context/ClauseProvider";

function App(): React.JSX.Element {
  initializeSentry();
  logEvent({ eventName: "plugin_load", fingerPrintIfNoToken: true });
  return (
    <AppWrapper>
      <AppContent />
    </AppWrapper>
  );
}

function AppContent(): React.JSX.Element {
  const [showChoosePlaybookModal, setShowChoosePlaybookModal] = React.useState(false);

  const { analyzedContract } = useAnalyzeContract();

  // Use a ref to track whether the component is mounted
  const isMountedRef = React.useRef(true);

  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const childComponent = analyzedContract == null ? <Home /> : <AnalyzedDocumentResults />;

  return (
    <ContentWrapper
      showChoosePlaybookModal={showChoosePlaybookModal}
      setShowChoosePlaybookModal={setShowChoosePlaybookModal}
    >
      {isMountedRef.current && childComponent}
    </ContentWrapper>
  );
}

function ContentWrapper({
  children,
  showChoosePlaybookModal,
  setShowChoosePlaybookModal,
}: {
  children: React.ReactNode;
  showChoosePlaybookModal: boolean;
  setShowChoosePlaybookModal: (arg: boolean) => void;
}): React.JSX.Element {
  const { token, signIn } = useAuthToken();
  const nonNullToken: string = token!;

  return (
    <Stack className="top-stack">
      {children}
      {showChoosePlaybookModal && (
        <ChoosePlaybookModal isOpen={showChoosePlaybookModal} onDismiss={() => setShowChoosePlaybookModal(false)} />
      )}
      {token && <Footer signIn={signIn} token={nonNullToken} setShowChoosePlaybookModal={setShowChoosePlaybookModal} />}
    </Stack>
  );
}

function Footer({
  setShowChoosePlaybookModal,
  token,
  signIn,
}: {
  setShowChoosePlaybookModal: (arg: boolean) => void;
  token: string;
  signIn: TSignIn;
}): React.JSX.Element {
  const { currentPlaybook } = useAnalyzeContract();
  const { showFeedbackModal } = useFeedbackModal();
  const { logOut } = useAuthToken();
  const menuItems: ICommandBarItemProps[] = [
    {
      key: "giveFeedback",
      text: "Give feedback",
      iconProps: { iconName: "Feedback", className: "menu-icon" },
      onClick: () => showFeedbackModal({ signIn, shouldShow: true, token, payload: { source: "Clicked from footer" } }),
    },
    {
      key: "logOut",
      text: "Sign out",
      iconProps: { iconName: "Leave", className: "menu-icon" },
      onClick: () => {
        if (logOut) {
          logOut();
        }
      },
    },
  ];
  if (currentPlaybook != null && currentPlaybook.id != null) {
    // eslint-disable-next-line no-undef
    if (Office.context.requirements.isSetSupported("OpenBrowserWindowAPI", "1.1")) {
      menuItems.unshift({
        key: "editPlaybook",
        text: "Edit playbook",
        iconProps: { iconName: "Edit", className: "menu-icon" },
        onClick: () => {
          // eslint-disable-next-line no-undef
          Office.context.ui.openBrowserWindow(`https://app.pincites.com/playbooks/${currentPlaybook.id}`);
        },
      });
    }
    menuItems.unshift({
      key: "switchPlaybook",
      text: "Switch playbook",
      iconProps: { iconName: "Switch", className: "menu-icon" },
      onClick: () => setShowChoosePlaybookModal(true),
    });
  }

  const items: ICommandBarItemProps[] = [
    {
      key: "settings",
      ariaLabel: "settings",
      iconProps: {
        iconName: "Settings",
        className: "menu-icon",
      },
      onClick: () => {},
      subMenuProps: {
        items: menuItems,
      },
    },
  ];

  return (
    <div className="footer-content">
      <CommandBar
        items={[]}
        farItems={items}
        ariaLabel="Use left and right arrow keys to navigate between commands"
        className="footer-command-bar"
      />
    </div>
  );
}

function AppWrapper({ children }: { children: React.ReactNode }): React.JSX.Element {
  return (
    <Sentry.ErrorBoundary
      fallback={() => (
        <p>An Error Has Occured. The team will work to get fixed as soon as possible. Please try again later</p>
      )}
      beforeCapture={(scope) => {
        // Try to capture user information on the error
        try {
          // eslint-disable-next-line no-undef
          const userName = Office.context.mailbox.userProfile.displayName;
          scope.setExtras({
            user_name: userName,
          });
        } catch (e) {
          // eslint-disable-next-line no-undef
          console.log("error logging: ", e);
        }
      }}
    >
      <AuthProvider>
        <FeedbackProvider>
          <ClauseProvider>
            <AnalyzeContractProvider>
              <SuggestedChangesProvider>
                <ErrorHandlingProvider>{children}</ErrorHandlingProvider>
              </SuggestedChangesProvider>
            </AnalyzeContractProvider>
          </ClauseProvider>
        </FeedbackProvider>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
