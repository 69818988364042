import React, { useState, PropsWithChildren } from "react";
import ErrorHandlingContext from "./ErrorHandlingContext";
import ErrorDialog from "./ErrorDialog";
import { Maybe } from "../types/ClauseTypes";
import { logErrorForAuthenticatedUser, logErrorForUnauthenticatedUser } from "../logging/Sentry";

const ErrorHandlingProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [errorMsg, setErrorMsg] = useState<Maybe<string>>(null);
  const [shouldShowRescanButton, setShouldShowRescanButton] = useState<boolean>(false);

  const showErrorDialog = (errorData: {
    errorMsg: string;
    error: Error;
    token?: string;
    skipLogging?: boolean;
    showRescanButton?: boolean;
  }) => {
    setErrorMsg(errorData.errorMsg);
    setShouldShowRescanButton(errorData.showRescanButton ?? false);
    const shouldSkipLogging = errorData.skipLogging === true;
    if (shouldSkipLogging) {
      return;
    }
    if (errorData.token != null) {
      logErrorForAuthenticatedUser({
        error: errorData.error,
        token: errorData.token,
        metadata: { errorMsg },
      });
    } else {
      logErrorForUnauthenticatedUser({ error: errorData.error, metadata: { errorMsg } });
    }
  };

  const hideErrorDialog = () => {
    setErrorMsg(null);
  };

  return (
    <ErrorHandlingContext.Provider value={{ showErrorDialog }}>
      {children}
      {errorMsg && (
        <ErrorDialog errorMsg={errorMsg} hideDialog={hideErrorDialog} showRescanButton={shouldShowRescanButton} />
      )}
    </ErrorHandlingContext.Provider>
  );
};

export default ErrorHandlingProvider;
