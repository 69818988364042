import React, { ReactNode } from "react";
import { Maybe } from "../types/ClauseTypes";
import { Stack } from "@fluentui/react";
import { TClauseMatchingType } from "../types/ClauseMatching";
import ClausesListHeader from "./ClausesListHeader";
import ClauseDetails from "./ClauseDetails";
import ClauseLink from "./ClauseLink";
import AlternativeLanguageBreakdown from "../components/AlternativeLanguageBreakdown";
import ClauseBreadcrumb from "./ClauseBreadcrumb";
import useAnalyzeContract from "../context/analyze_contract/useAnalyzeContract";
import useClause from "../context/clause_context/useClause";

function ClauseResults(): React.JSX.Element {
  let content: Maybe<React.JSX.Element> = null;
  const { selectedClause } = useClause();
  if (selectedClause != null) {
    content = <TemplateDetails />;
  } else {
    content = <ClauseList />;
  }

  return <ContentWrapper>{content}</ContentWrapper>;
}

function ContentWrapper({ children }: { children: ReactNode }): React.JSX.Element {
  const { selectedClause } = useClause();
  return (
    <Stack id="clause-list-container" className="clause-list-container">
      {!selectedClause && <ClausesListHeader />}
      {children}
    </Stack>
  );
}

function TemplateDetails(): React.JSX.Element {
  return (
    <Stack>
      <ClauseBreadcrumb />
      <Stack className={`template-details-container`}>
        <ClauseDetails />
        <AlternativeLanguageBreakdown />
      </Stack>
    </Stack>
  );
}

function ClauseList(): React.JSX.Element {
  const { analyzedContract } = useAnalyzeContract();
  return (
    <Stack tokens={{ childrenGap: 10 }} className="clause-link-list">
      {(analyzedContract?.matchedClauses ?? []).map((matchedAnalyzedClause) => {
        return (
          <ClauseLink
            key={matchedAnalyzedClause.clauseTemplate.id}
            clause={matchedAnalyzedClause.clauseTemplate}
            matchedClause={matchedAnalyzedClause}
            clauseMatchingType={
              matchedAnalyzedClause.isStandardText ? TClauseMatchingType.STANDARD : TClauseMatchingType.CHANGED
            }
          />
        );
      })}
      {(analyzedContract?.missingClauses ?? []).map((missingAnalyzedClause) => {
        return (
          <ClauseLink
            key={missingAnalyzedClause.clauseTemplate.id}
            clause={missingAnalyzedClause.clauseTemplate}
            matchedClause={null}
            clauseMatchingType={TClauseMatchingType.MISSING}
          />
        );
      })}
    </Stack>
  );
}

export default ClauseResults;
