import { ReplaceStep } from "prosemirror-transform";
import { getHumanReadableDiff } from "./DiffUtils";
import { Fragment, Schema, Slice } from "prosemirror-model";
import { SuggestionStatusType, TGuidanceSuggestion } from "../types/SuggestionTypes";

// In the backend we store step indices as 0 indexed and represent where in the text field
// a change ought to go. While technically ProseMirror is 0 indexed,
// there is always a paragraph in front of the text and that takes up the first index.
const INDEX_ADJUSTMENT = 1;

export function getSuggestionStepsFromTexts(oldText: string, newText: string): ReplaceStep[] {
  const diffs = getHumanReadableDiff(oldText, newText);
  const changes: ReplaceStep[] = [];
  const schema = getProseMirrorSchema();
  let position = 0;

  for (let i = 0; i < diffs.length; i++) {
    const diff = diffs[i];
    if (diff.removed) {
      // Check if the next diff was something added
      // right after something removed - then that is a replacement
      if (i + 1 < diffs.length && diffs[i + 1].added) {
        const nextDiff = diffs[i + 1];
        const replaceNode = schema.text(nextDiff.value);
        const replaceFragment = Fragment.from(replaceNode);
        const replaceSlice = new Slice(replaceFragment, 0, 0);
        changes.push(
          new ReplaceStep(position + INDEX_ADJUSTMENT, position + INDEX_ADJUSTMENT + diff.value.length, replaceSlice)
        );
        position += nextDiff.value.length;
        i++; // Skip the next diff entry
      } else {
        changes.push(
          new ReplaceStep(position + INDEX_ADJUSTMENT, position + INDEX_ADJUSTMENT + diff.value.length, Slice.empty)
        );
      }
    } else if (diff.added) {
      const addNode = schema.text(diff.value);
      const addFragment = Fragment.from(addNode);
      const addSlice = new Slice(addFragment, 0, 0);
      changes.push(new ReplaceStep(position + INDEX_ADJUSTMENT, position + INDEX_ADJUSTMENT, addSlice));
      position += diff.value.length;
    } else {
      position += diff.value.length;
    }
  }

  return changes;
}

export function encodeSuggestedChangeSteps(suggestionSteps: ReplaceStep[]): string {
  return JSON.stringify(suggestionSteps);
}

export function getCurrentBaseVersion(allSuggestions: TGuidanceSuggestion[]): number {
  // get the current version by looking at the count
  // of accepted suggestion
  return allSuggestions.filter(filterBySuggestionStatus(SuggestionStatusType.ACCEPTED)).length;
}

function filterBySuggestionStatus(status: SuggestionStatusType) {
  return (suggestion: TGuidanceSuggestion) => suggestion.status === status;
}

const SCHEMA = new Schema({
  nodes: {
    doc: { content: "paragraph+" },
    paragraph: { content: "text*" },
    text: {},
  },
});

// All ProseMirror objects must use the same schema instance
export function getProseMirrorSchema(): Schema {
  return SCHEMA;
}
