import { PrimaryButton, Spinner, Stack, DefaultButton, Link, SpinnerSize, Image } from "@fluentui/react";
import React, { useState } from "react";
import { useAuthToken } from "../auth/useAuthToken";
import ChoosePlaybookModal from "./ChoosePlaybookModal";
import PincitesText from "../library/PincitesText";
import PincitesHeading, { PincitesHeadingType } from "../library/PincitesHeading";

function Home(): React.JSX.Element {
  const { isAuthenticated } = useAuthToken();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Stack className="welcome-banner">
      <WelcomeText />
      {!isAuthenticated && <LoginSection />}
      {isAuthenticated && (
        <Stack.Item align="center">
          <PrimaryButton
            className="choose-playbook-button"
            text="Choose Playbook"
            onClick={() => setIsModalOpen(true)}
          />
          <ChoosePlaybookModal isOpen={isModalOpen} onDismiss={() => setIsModalOpen(false)} />
        </Stack.Item>
      )}
    </Stack>
  );
}
function LoginSection(): React.JSX.Element {
  const { signIn, isAuthenticating } = useAuthToken();
  if (isAuthenticating) {
    return <LoadingSpinner />;
  }
  return (
    <Stack horizontalAlign="center">
      <div className="microsoft-login-button-wrapper">
        <DefaultButton
          className="microsoft-login-button"
          onClick={async () => {
            if (signIn) {
              await signIn();
            }
          }}
        >
          <Stack horizontal verticalAlign="center" className="microsoft-login-button-content">
            <Image src="assets/microsoft-logo.svg" alt="Microsoft Logo" />
            Log in with Microsoft
          </Stack>
        </DefaultButton>
      </div>
      <Link className="waitlist-link" href="https://www.pincites.com/contact">
        <b>Request a demo</b>
      </Link>
    </Stack>
  );
}

function WelcomeText(): React.JSX.Element {
  return (
    <Stack className="welcome-banner-text-container">
      <PincitesHeading headingType={PincitesHeadingType.APP_TITLE} customStyle={{ color: "#fff", textAlign: "center" }}>
        Welcome to Pincites
      </PincitesHeading>
      <PincitesText customStyle={{ color: "#fff", textAlign: "center", fontSize: 14 }}>
        Review your contracts faster. Based on your playbooks, and powered by advanced language models.
      </PincitesText>
    </Stack>
  );
}

function LoadingSpinner(): React.JSX.Element {
  return (
    <Stack horizontalAlign="center">
      <Spinner size={SpinnerSize.large} className="spinner" label="Authenticating..." />
    </Stack>
  );
}

export default Home;
