import React, { useState } from "react";
import { Modal, TextField, PrimaryButton, DefaultButton, Stack } from "@fluentui/react";
import { submitFeedback } from "../utils/apiUtils";
import useErrorHandling from "../error_handling/useErrorHandling";
import { Maybe } from "../types/ClauseTypes";
import { TSignIn } from "../auth/AuthContext";
import jwt_decode from "jwt-decode";
import { DecodedMicrosoftToken } from "../types/Microsoft";

const FeedbackModalForm = ({
  closeModal,
  token,
  initialDescription,
  initialDetails,
  signIn,
  payload,
}: {
  closeModal: () => void;
  token: string;
  signIn: TSignIn;
  initialDescription: Maybe<string>;
  initialDetails: Maybe<string>;
  payload: Record<string, any>;
}) => {
  const [feedbackSummary, setFeedbackSummary] = useState<string>(initialDescription ?? "");
  const [feedbackDetails, setFeedbackDetails] = useState<string>(initialDetails ?? "");

  const { showErrorDialog } = useErrorHandling();

  return (
    <Modal
      isOpen={true}
      onDismiss={closeModal}
      isBlocking={true}
      styles={{
        main: {
          height: "430px",
        },
      }}
    >
      <div className="modal-form-container">
        <h2>How can we help?</h2>
        <TextField
          label="Brief description"
          className="text-field"
          value={feedbackSummary}
          onChange={(_, newValue) => setFeedbackSummary(newValue ?? "")}
          placeholder="What’s on your mind?"
        />
        <TextField
          multiline
          rows={10}
          className="text-field"
          placeholder="Take all the space you need, and give us your detailed feedback."
          label="Details"
          value={feedbackDetails}
          onChange={(_, newValue) => setFeedbackDetails(newValue ?? "")}
        />
        <div className="modal-footer">
          <Stack horizontal horizontalAlign="center">
            <DefaultButton className="modal-button modal-button-secondary" text="Cancel" onClick={() => closeModal()} />
            <PrimaryButton
              className="modal-button modal-button-primary"
              text="Send"
              onClick={() => {
                const feedbackDetailsWithIdentifyingInfo = getFeedbackDetailsWithIdentifyingInfo(
                  token,
                  feedbackDetails,
                  payload
                );
                submitFeedback({
                  signIn,
                  feedbackSummary,
                  feedbackDetails: feedbackDetailsWithIdentifyingInfo,
                  token,
                  showErrorDialog,
                });
                closeModal();
              }}
            />
          </Stack>
        </div>
      </div>
    </Modal>
  );
};

function getFeedbackDetailsWithIdentifyingInfo(
  token: string,
  feedbackDetails: string,
  payload: Record<string, any>
): string {
  const decodedToken: DecodedMicrosoftToken = jwt_decode(token);
  const userName = decodedToken.name;
  const userEmail = decodedToken.email;
  const payloadStr = JSON.stringify(payload, null, 2);
  const formattedFeedback = `
  ${feedbackDetails}
  
  -----------------------------
  Extra Info:
  ${payloadStr}
  -----------------------------
  User Info:
  Name: ${userName}
  Email: ${userEmail}
  -----------------------------
  `;
  return formattedFeedback;
}

export default FeedbackModalForm;
