import React from "react";
import { Maybe } from "../types/ClauseTypes";

export type TSignIn = Maybe<() => Promise<string>>;

type AuthContextType = {
  token: Maybe<string>;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  signIn: TSignIn;
  logOut: Maybe<() => void>;
};

export const AuthContext = React.createContext<AuthContextType>({
  token: null,
  isAuthenticated: false,
  isAuthenticating: false,
  signIn: null,
  logOut: null,
});
