import { DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import React from "react";
import { Margin, getCSStylesFromMargins } from "./Margin";

export default function PincitesActionButton({
  isDisabled = false,
  onClick,
  Icon,
  label,
  showSpinner,
  margin = [],
}: {
  isDisabled?: boolean;
  onClick: () => void;
  Icon?: React.ComponentType<{ className?: string }>;
  label: string;
  showSpinner?: boolean;
  margin?: Margin[];
}): React.JSX.Element {
  return (
    <DefaultButton
      className="action-button-root"
      disabled={isDisabled}
      onClick={onClick}
      style={getCSStylesFromMargins(margin)}
      ariaLabel={label}
    >
      {showSpinner ? (
        <Spinner size={SpinnerSize.medium} />
      ) : (
        <>
          {Icon && <Icon />}
          <p className="action-button-text padded">{label}</p>
        </>
      )}
    </DefaultButton>
  );
}
