import React from "react";
import { Maybe, TAnalyzedContract, TPlaybook } from "../../types/ClauseTypes";

export type TAnalyzeContractContextProps = {
  analyzedContract: Maybe<TAnalyzedContract>;
  clearAnalyzedContract: () => void;
  setAnalyzedContract: (arg: TAnalyzedContract) => void;
  currentPlaybook: Maybe<TPlaybook>;
  setCurrentPlaybook: (arg: TPlaybook) => void;
  getUserDefinedGuidanceForClause: (clauseId: string) => Maybe<string>;
  setUserDefinedGuidanceForClause: (clauseId: string, guidance: string) => void;
};

const defaultAnalyzeContractContextValue: TAnalyzeContractContextProps = {
  analyzedContract: null,
  clearAnalyzedContract: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setAnalyzedContract: (_analyzedContract) => {},
  currentPlaybook: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCurrentPlaybook: (_playbook) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getUserDefinedGuidanceForClause: (_clauseId) => null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setUserDefinedGuidanceForClause: (_clauseId, _guidance) => {},
};

const AnalyzeContractContext = React.createContext(defaultAnalyzeContractContextValue);

export default AnalyzeContractContext;
