import { TooltipHost } from "@fluentui/react";
import React from "react";

export default function PincitesIconButton({
  tooltip,
  Icon,
  onClick,
  style,
}: {
  onClick: () => void;
  tooltip?: string;
  Icon: React.ComponentType<{ className?: string; onClick: () => void; style?: React.CSSProperties }>;
  style?: React.CSSProperties;
}): React.JSX.Element {
  return (
    <TooltipHost content={tooltip}>
      <Icon className="action-button-icon" onClick={onClick} style={{ ...style, cursor: "pointer" }} />
    </TooltipHost>
  );
}
