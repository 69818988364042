import React, { useState, PropsWithChildren } from "react";
import ClauseContext, { ClauseResult } from "./ClauseContext";
import { Maybe, TClauseTemplate, TMatchedClause } from "../../types/ClauseTypes";

const ClauseProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [currentClauseResult, setCurrentClauseResult] = useState<ClauseResult>({
    selectedClause: null,
    matchedClause: null,
  });
  const setClauseResult = (arg: { selectedClause?: Maybe<TClauseTemplate>; matchedClause?: Maybe<TMatchedClause> }) => {
    const updatedResult: ClauseResult = { ...currentClauseResult };

    if ("selectedClause" in arg) {
      updatedResult.selectedClause = arg.selectedClause;
    }

    if ("matchedClause" in arg) {
      updatedResult.matchedClause = arg.matchedClause;
    }

    setCurrentClauseResult(updatedResult);
  };

  const navigateToClauseList = () => {
    setClauseResult({ selectedClause: null, matchedClause: currentClauseResult.matchedClause });
  };

  return (
    <ClauseContext.Provider
      value={{
        selectedClause: currentClauseResult.selectedClause,
        matchedClause: currentClauseResult.matchedClause,
        navigateToClauseList,
        setClauseResult,
      }}
    >
      {children}
    </ClauseContext.Provider>
  );
};

export default ClauseProvider;
