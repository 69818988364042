import React from "react";

export enum PincitesBadgeType {
  GRAY = "GRAY",
  ORANGE = "ORANGE",
  DANGER = "DANGER",
  GREEN = "GREEN",
  GRAY_DARK = "GRAY-DARK",
}

export default function PincitesBadge({
  label,
  badgeType,
}: {
  label: string;
  badgeType: PincitesBadgeType;
}): React.JSX.Element {
  return <div className={`clause-type-badge ${getCSSClassNameByBadgeType(badgeType)}`}>{label}</div>;
}

function getCSSClassNameByBadgeType(badgeType: PincitesBadgeType): string {
  switch (badgeType) {
    case PincitesBadgeType.GRAY:
      return "pincites-badge-gray";
    case PincitesBadgeType.DANGER:
      return "pincites-badge-danger";
    case PincitesBadgeType.ORANGE:
      return "pincites-badge-orange";
    case PincitesBadgeType.GREEN:
      return "pincites-badge-green";
    case PincitesBadgeType.GRAY_DARK:
      return "pincites-badge-gray-dark";
  }
}
