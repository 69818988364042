import React, { PropsWithChildren, useState } from "react";
import FeedbackContext, { TFeedbackModalProps } from "./FeedbackContext";
import FeedbackModalForm from "./FeedbackModalForm";

const FeedbackProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [feedbackModalProps, setFeedbackModalProps] = useState<TFeedbackModalProps>({
    shouldShow: false,
    token: null,
    signIn: null,
  });

  const token = feedbackModalProps.token;
  return (
    <FeedbackContext.Provider value={{ showFeedbackModal: setFeedbackModalProps }}>
      {children}
      {feedbackModalProps.shouldShow && token && (
        <FeedbackModalForm
          token={token}
          signIn={feedbackModalProps.signIn}
          closeModal={() => setFeedbackModalProps({ shouldShow: false, token, signIn: feedbackModalProps.signIn })}
          initialDescription={feedbackModalProps.initialDescription}
          initialDetails={feedbackModalProps.initialDetails}
          payload={feedbackModalProps.payload ?? {}}
        />
      )}
    </FeedbackContext.Provider>
  );
};

export default FeedbackProvider;
