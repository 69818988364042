import App from "./components/Pincites";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { ThemeProvider } from "@fluentui/react";
import * as React from "react";
import { createRoot } from "react-dom/client";

/* global document, Office, module */

initializeIcons();

let isOfficeInitialized = false;

const container = document.getElementById("container") as HTMLElement;
const root = createRoot(container);
const render = (Component: React.ComponentType<{ isOfficeInitialized: boolean }>) => {
  root.render(
    <ThemeProvider>
      <Component isOfficeInitialized={isOfficeInitialized} />
    </ThemeProvider>
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  render(App);
});

if ((module as any).hot) {
  (module as any).hot.accept("./components/Pincites", () => {
    const PincitesApp = require("./components/Pincites").default;
    render(PincitesApp);
  });
}
