import React from "react";
import { Stack, StackItem } from "@fluentui/react";
import useAnalyzeContract from "../context/analyze_contract/useAnalyzeContract";
import PincitesText, { PincitesTextType } from "../library/PincitesText";
import PincitesHeading, { PincitesHeadingType } from "../library/PincitesHeading";
import { RescanButton } from "../analyze/RescanButton";

function ClausesListHeader(): React.JSX.Element {
  const { currentPlaybook } = useAnalyzeContract();
  return (
    <Stack>
      <Stack horizontal className="template-header">
        <Stack>
          <PincitesHeading headingType={PincitesHeadingType.H1_UNBOLDED}>Clauses</PincitesHeading>
          <PincitesText textType={PincitesTextType.SUB_TEXT}>{currentPlaybook!.name}</PincitesText>
        </Stack>
        <StackItem styles={{ root: { marginLeft: "auto" } }}>
          <RescanButton resetClauseViewOnClick={false} />
        </StackItem>
      </Stack>
    </Stack>
  );
}

export default ClausesListHeader;
