import sax from "sax";

/**
 * Word expects the text passed to `search()` to be in a surprising format when there are
 * tracked changes in the document. This function builds a translator that will convert
 * a string from the "current" version of the document (the version with all tracked changes
 * accepted) to the format Word expects in the `search()` function (which includes text that
 * has been deleted).
 */
export function parseOoxml(ooxml: string): {
  searchTranslator: (textToTranslate: string, startPosition?: number | undefined) => string;
  currentText: string;
  originalText: string;
} {
  // Parser for getting the text of the document
  let parser = sax.parser(true);

  // Store the current text (the text of the document with all tracked changes accepted),
  // the original text (the text of the document with all tracked changes rejected),
  // and the diff view text (the text of the document with all tracked changes shown,
  // including deletions).
  let currentText = "";
  let originalText = "";
  let diffViewText = "";

  // Store an index adjustment between the current text and the diff view text, used to
  // create the "searchTranslator" function
  let indexAdjustments: {
    index: number;
    adjustment: number;
  }[] = [];

  // Store flags for where we are in the document as we parse it
  let inBody = false;
  let inParagraph = false;
  let inParagraphProperties = false;
  let inRunProperties = false;
  let inTableRowProperties = false;
  let inDeletion = false;
  let inInsertion = false;
  let inFieldCode = false;
  let currentParagraphHasBreakDeleted = false;
  let currentTableRowHasBeenDeleted = false;
  let currentParagraphHasBreakInserted = false;
  let currentTableRowHasBeenInserted = false;
  let currentParagraphEndsWithFormFeed = false;
  let enqueuedParagraphBreak = false;
  let enqueuedParagraphBreakHasBeenDeleted = false;
  let enqueuedParagraphBreakHasBeenInserted = false;
  let enqueuedTableCellTab = false;

  const recordText = function (t: string) {
    if (!inBody) {
      // Ignore the text if it's not in the body of the document
      return;
    }
    if (inFieldCode) {
      // Ignore the text if it's in a field code. It doesn't show up in the document
      return;
    }
    if (!inParagraph) {
      // All the document text we're interested in appears in paragraph blocks)
      return;
    }

    if (!inInsertion) {
      originalText += t;
    }

    if (!inDeletion) {
      currentText += t;
      diffViewText += t;
    } else {
      diffViewText += t;
      // Store the index of the deleted text, and the length of the deleted text
      // so we can adjust the index of the matched clause to account for the deleted text
      indexAdjustments.push({ index: currentText.length, adjustment: t.length });
    }
  };

  const recordBreak = function (character: string, deleted: boolean, inserted: boolean) {
    if (!inBody) {
      // Ignore the text if it's not in the body of the document
      return;
    }
    const t = character;
    if (!inserted) {
      originalText += t;
    }
    if (!deleted) {
      currentText += t;
      diffViewText += t;
    } else {
      diffViewText += t;
      // Store the index of the deleted text, and the length of the deleted text
      // so we can adjust the index of the matched clause to account for the deleted text
      indexAdjustments.push({ index: currentText.length, adjustment: t.length });
    }
  };

  parser.onopentag = function (node) {
    switch (node.name) {
      case "w:body":
        inBody = true;
        break;
      case "w:p":
        // Handle any previously enqueued paragraph break
        if (enqueuedParagraphBreak) {
          recordBreak("\r", enqueuedParagraphBreakHasBeenDeleted, enqueuedParagraphBreakHasBeenInserted);
          enqueuedParagraphBreak = false;
          enqueuedParagraphBreakHasBeenDeleted = false;
          enqueuedParagraphBreakHasBeenInserted = false;
        }

        // Set the inParagraph flag to true so we know to store the text
        inParagraph = true;
        break;
      case "w:tc":
        // Handle any previously enqueued tab
        if (enqueuedTableCellTab) {
          recordBreak("\t", currentTableRowHasBeenDeleted, currentTableRowHasBeenInserted);
          enqueuedTableCellTab = false;
        }
        break;
      case "w:pPr":
        // Set the inParagraphProperties flag to true so we know to ignore the text
        inParagraphProperties = true;
        break;
      case "w:rPr":
        // Set the inRunProperties flag to true so we know to ignore the text
        inRunProperties = true;
        break;
      case "w:trPr":
        // Set the inTableRowProperties flag to true so we know to ignore the text
        inTableRowProperties = true;
        break;
      case "w:sectPr":
        // Set the currentParagraphEndsWithFormFeed flag to true so we know to enqueue a form feed
        // instead of a paragraph break
        currentParagraphEndsWithFormFeed = true;
        break;
      case "w:del":
        // Set the inDeletion flag to true so we know to ignore the text
        inDeletion = true;
        if (inParagraphProperties && inRunProperties) {
          currentParagraphHasBreakDeleted = true;
        }
        if (inTableRowProperties) {
          currentTableRowHasBeenDeleted = true;
        }
        break;
      case "w:ins":
        inInsertion = true;
        if (inParagraphProperties && inRunProperties) {
          currentParagraphHasBreakInserted = true;
        }
        if (inTableRowProperties) {
          currentTableRowHasBeenInserted = true;
        }
        break;
      case "w:instrText":
      case "w:delInstrText":
        // Set the inFieldCode flag to true so we know to ignore the text
        inFieldCode = true;
        break;
    }
  };

  parser.onclosetag = function (tagName) {
    switch (tagName) {
      case "w:body":
        // Set the inBody flag to false so we know to stop storing the text
        inBody = false;
        break;
      case "w:pPr":
        // Set the inParagraphProperties flag to false so we know to stop ignoring the text
        inParagraphProperties = false;
        break;
      case "w:rPr":
        // Set the inRunProperties flag to false so we know to stop ignoring the text
        inRunProperties = false;
        break;
      case "w:trPr":
        // Set the inTableRowProperties flag to false so we know to stop ignoring the text
        inTableRowProperties = false;
        break;
      case "w:p":
        if (currentParagraphEndsWithFormFeed) {
          // If the paragraph has a section formatting information in it then it should end with
          // a form feed instead of a paragraph break, and we can create it straight away
          recordBreak("\f", currentParagraphHasBreakDeleted, currentParagraphHasBreakInserted);
        } else {
          // Otherwise we enqueue creating a paragraph break. We'll create it as long as we encounter
          // another paragraph before hitting the end of a table cell (or the document)
          enqueuedParagraphBreakHasBeenDeleted = currentParagraphHasBreakDeleted;
          enqueuedParagraphBreakHasBeenInserted = currentParagraphHasBreakInserted;
          enqueuedParagraphBreak = true;
        }

        // Clear the current paragraph details
        inParagraph = false;
        currentParagraphHasBreakDeleted = false;
        currentParagraphHasBreakInserted = false;
        currentParagraphEndsWithFormFeed = false;
        break;
      case "w:tc":
        // Cancel creating any previously enqueued paragraph break
        enqueuedParagraphBreak = false;
        enqueuedParagraphBreakHasBeenDeleted = false;
        enqueuedParagraphBreakHasBeenInserted = false;

        // Enqueue creating a tab. We'll create it as long as we encounter another
        // table cell before hitting the end of a table row
        enqueuedTableCellTab = true;
        break;
      case "w:tr":
        // Add a paragraph break for every new row
        recordBreak("\r", currentTableRowHasBeenDeleted, currentTableRowHasBeenInserted);
        currentTableRowHasBeenDeleted = false;
        currentTableRowHasBeenInserted = false;

        // Handle any previously enqueued tab
        enqueuedTableCellTab = false;
        break;
      case "w:tab":
        if (inParagraph && !inParagraphProperties) {
          // Store tabs as tabs (unless we're just definining their styling, in which case ignore them)
          recordText("\t");
        }
        break;
      case "w:noBreakHyphen":
        // Store noBreakHyphens as regular hyphens. (Word searches for them as regular hyphens)
        recordText("-");
        break;
      case "w:br":
        // Store line breaks as line breaks. (Word won't find them if we store them as \r)
        recordText("\u000b");
        break;
      case "w:del":
        // Set the inDeletion flag to false so we know to stop ignoring the text
        inDeletion = false;
        break;
      case "w:ins":
        inInsertion = false;
        break;
      case "w:instrText":
      case "w:delInstrText":
        // Set the inFieldCode flag to false so we know to stop ignoring the text
        inFieldCode = false;
        break;
    }
  };

  parser.ontext = recordText;

  // Parse the OOXML provided
  parser.write(ooxml).close();

  const translator = (textToTranslate: string, startPosition?: number | undefined) => {
    const startIndex = currentText.indexOf(textToTranslate, startPosition);
    // If the text is not found, return the original text
    const endIndex = startIndex + textToTranslate.length;
    if (startIndex === -1) {
      return textToTranslate;
    }

    // Adjust that index based on the deleted text
    let adjustedStartIndex = startIndex;
    let adjustedEndIndex = endIndex;
    indexAdjustments.forEach((adjustment) => {
      if (adjustment.index <= startIndex) {
        adjustedStartIndex += adjustment.adjustment;
      }
      if (adjustment.index < endIndex) {
        adjustedEndIndex += adjustment.adjustment;
      }
    });

    return diffViewText.substring(adjustedStartIndex, adjustedEndIndex);
  };

  return {
    searchTranslator: translator,
    currentText: currentText,
    originalText: originalText,
  };
}

export function parseOoxmlForSuggestedChanges(ooxml: string): boolean {
  // Parser for getting the text of the document
  let parser = sax.parser(true);

  let inSuggestedChange = false;
  let hasSuggestedChanges = false;

  parser.onopentag = function (node) {
    if (["w:del", "w:ins"].includes(node.name)) {
      inSuggestedChange = true;
    }
  };

  parser.onclosetag = function (tagName) {
    if (["w:del", "w:ins"].includes(tagName)) {
      inSuggestedChange = false;
    }
  };

  parser.ontext = function (t) {
    if (inSuggestedChange && t !== "") {
      hasSuggestedChanges = true;
    }
  };

  // Parse the OOXML provided
  parser.write(ooxml).close();

  return hasSuggestedChanges;
}
