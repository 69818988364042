import React from "react";
import { Stack } from "@fluentui/react";
import { PreferenceLevel } from "../types/PreferenceLevel";
import ClausePreferenceLevelDetails from "../Clauses/ClausePreferenceLevelDetails";
import PincitesHeading, { PincitesHeadingType } from "../library/PincitesHeading";
import { Margin } from "../library/Margin";
import useClause from "../context/clause_context/useClause";

interface AlternativeLanguageBreakdownProps {}

const AlternativeLanguageBreakdown: React.FC<AlternativeLanguageBreakdownProps> = () => {
  const { selectedClause } = useClause();
  if (selectedClause!.clauseTextAlternatives.length === 0) {
    return null;
  }

  return (
    <Stack>
      <PincitesHeading headingType={PincitesHeadingType.H1} margin={[Margin.MARGIN_BOTTOM_6, Margin.MARGIN_TOP_16]}>
        Alternative language
      </PincitesHeading>
      {Object.values(PreferenceLevel).map((preferenceLevel) => {
        const matchingAlternatives = selectedClause!.clauseTextAlternatives.filter(
          (alternative) => alternative.preferenceLevel === preferenceLevel
        );

        return matchingAlternatives.map((alternativeClause) => (
          <ClausePreferenceLevelDetails key={alternativeClause.id} alternativeClause={alternativeClause} />
        ));
      })}
    </Stack>
  );
};

export default AlternativeLanguageBreakdown;
