import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { DecodedMicrosoftToken } from "../types/Microsoft";
import jwt_decode from "jwt-decode";
import { Maybe } from "../types/ClauseTypes";
import { getIsDevEnvironment } from "../utils/debuggingUtils";

export function getAzureApplicationInsights(): {
  appInsights: ApplicationInsights;
  reactPlugin: ReactPlugin;
} {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      connectionString:
        "InstrumentationKey=9c228eb2-11bd-448a-8e17-e468d3a4e60b;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/",
      extensions: [reactPlugin],
      // No data is sent to Application Insights in local development
      samplingPercentage: getIsDevEnvironment() ? 0 : 100,
      // We are solely interested in logging events so we disable telemetry
      disableFetchTracking: true,
      disableAjaxTracking: true,
      disableTelemetry: false,
      enableCorsCorrelation: false,
      enableRequestHeaderTracking: false,
      enableResponseHeaderTracking: false,
      autoTrackPageVisitTime: false,
      enableAutoRouteTracking: false,
    },
  });
  appInsights.loadAppInsights();
  return {
    appInsights,
    reactPlugin,
  };
}

export function logEvent({
  eventName,
  metadata,
  token,
  fingerPrintIfNoToken,
}: {
  eventName: string;
  token?: Maybe<string>;
  metadata?: Record<string, any>;
  fingerPrintIfNoToken?: boolean;
}): void {
  const { appInsights } = getAzureApplicationInsights();
  let loggingData: Record<string, any> = { payload: metadata };
  if (token != null) {
    const decodedToken: DecodedMicrosoftToken = jwt_decode(token);
    loggingData = {
      user_name: decodedToken.name,
      user_email: decodedToken.email,
      payload: metadata,
    };
  } else {
    if (fingerPrintIfNoToken) {
      try {
        loggingData = {
          // eslint-disable-next-line no-undef
          user_name: Office.context.mailbox.userProfile.displayName,
          // eslint-disable-next-line no-undef
          user_email: Office.context.mailbox.userProfile.emailAddress,
          payload: metadata,
        };
      } catch (_e) {
        loggingData = { payload: metadata };
      }
    }
  }
  appInsights.trackEvent({ name: eventName }, loggingData);
}
