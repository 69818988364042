import React, { useState, useRef, useEffect } from "react";
import { Icon } from "@fluentui/react/lib/Icon";

interface DropdownProps {
  options: { key: string; text: string; description: string }[];
  selectedKey?: string;
  placeholder?: string;
  onChange: (key: string) => void;
  isOpen: boolean;
  onToggleOpen: () => void;
  containerRef: React.RefObject<HTMLDivElement>;
}

function PlaybookSelectionDropdown({
  options,
  selectedKey,
  placeholder,
  onChange,
  isOpen,
  onToggleOpen,
  containerRef,
}: DropdownProps): React.JSX.Element {
  const [searchValue, setSearchValue] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      searchInputRef.current?.focus();
    }
    const handleClickOutside = (event: Event) => {
      if (isOpen && !containerRef.current?.contains(event.target as Node)) {
        onToggleOpen();
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      if (container) {
        container.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, [isOpen, onToggleOpen, containerRef]);

  const selectedOption = options.find((option) => option.key === selectedKey) || { text: placeholder || "" };

  const filteredOptions = options.filter((option) => option.text.toLowerCase().includes(searchValue.toLowerCase()));

  const handleButtonClick = () => {
    onToggleOpen();
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && filteredOptions.length > 0) {
      onChange(filteredOptions[0].key); // Set the first filtered option as selected
      onToggleOpen();
    }
  };

  const handleOptionClick = (key: string) => {
    onChange(key);
    onToggleOpen();
  };

  const handleOptionMouseDown = (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    const key = e.currentTarget.dataset.key;
    if (key) {
      handleOptionClick(key);
    }
  };

  return (
    <div className={`pincites-dropdown`}>
      {isOpen ? (
        <div className="pincites-dropdown button">
          <input
            ref={searchInputRef}
            type="text"
            value={searchValue}
            onChange={handleSearchChange}
            onKeyDown={handleInputKeyDown}
            placeholder="Choose playbook"
            onBlur={() => onToggleOpen()}
            className="pincites-dropdown button"
          />
          <ul className={isOpen ? "show" : ""} style={{ overflowY: "auto", maxHeight: "200px" }}>
            {filteredOptions.length === 0 ? (
              <li>
                <p className="pincites-dropdown-no-matches">No matching playbooks</p>
              </li>
            ) : (
              filteredOptions.map((option) => (
                <li
                  key={option.key}
                  onMouseDown={handleOptionMouseDown}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleOptionClick(option.key);
                    }
                  }}
                  tabIndex={0} // Make list items focusable
                  data-key={option.key} // Store the key as a dataset attribute
                >
                  {option.text}
                </li>
              ))
            )}
            <li onMouseDown={handleOptionMouseDown}>
              <a
                href={
                  options.length > 0 ? "https://app.pincites.com/playbooks" : "https://app.pincites.com/playbooks/new"
                }
                target="_blank"
                rel="noopener noreferrer"
                className="create-playbook-link"
              >
                <span className="link-text">{options.length > 0 ? "View playbooks" : "Create playbook"}</span>
                <Icon className="open-in-new-window" iconName="OpenInNewWindow" />
              </a>
            </li>
          </ul>
        </div>
      ) : (
        <button onClick={handleButtonClick} className="pincites-dropdown button">
          {selectedOption.text}
        </button>
      )}
    </div>
  );
}

export default PlaybookSelectionDropdown;
