import React from "react";
import { Maybe } from "../types/ClauseTypes";
import { TSignIn } from "../auth/AuthContext";

export type TFeedbackModalProps = {
  shouldShow: boolean;
  token: Maybe<string>;
  signIn: TSignIn;
  initialDescription?: string;
  initialDetails?: string;
  payload?: Record<string, any>;
};

export type TShowFeedbackModal = (feedbackModalProps: TFeedbackModalProps) => void;

interface FeedbackContextProps {
  showFeedbackModal: TShowFeedbackModal;
}

const defaultFeedbackContextValue: FeedbackContextProps = {
  showFeedbackModal: () => {},
};

const FeedbackContext = React.createContext(defaultFeedbackContextValue);

export default FeedbackContext;
