import React, { useState, useEffect } from "react";
import { Stack } from "@fluentui/react";
import {
  TClauseMatchingType,
  getBadgeTypeFromMatchingType,
  getDoesClauseMatchingTypeSupportDiffing,
  getLabelForMatchingTypeAndInclusionRequirement,
} from "../types/ClauseMatching";
import { Maybe, TMatchedClause, TSuggestedChange } from "../types/ClauseTypes";
import {
  CommentArrowRight12Regular,
  CommentAdd12Regular,
  Warning12Regular,
  Edit20Regular,
  Info12Regular,
  ArrowReset20Regular,
} from "@fluentui/react-icons";
import { markSuggestedChangeApplied, suggestClauseChanges } from "../utils/apiUtils";
import useErrorHandling from "../error_handling/useErrorHandling";
import { useAuthToken } from "../auth/useAuthToken";
import { FormattedDiffText } from "../utils/DiffUtils";
import { getTextStatusForMatchedClause, redlineAndCommentOnMatchedClause } from "../utils/wordUtils";
import {
  InclusionRequirement,
  getDoesInclusionTypeSupportDiffing,
  getLabelForInclusionRequirement,
  getShouldInclusionTypeBeDisplayedWithClauseHeader,
} from "../types/InclusionRequirement";
import { RescanButton } from "../analyze/RescanButton";
import GuidanceTextComponent from "./GuidanceTextComponent";
import PincitesExpandableText from "../library/PincitesExpandableText";
import PincitesBadge, { PincitesBadgeType } from "../library/PincitesBadge";
import PincitesCopyButton from "../library/PincitesCopyButton";
import PincitesThumbFeedbackButton from "../library/PincitesThumbFeedbackButton";
import { getIsUsingOfficeOnline } from "../utils/officeEnvironmentUtils";
import PincitesActionButton from "../library/PincitesActionButton";
import PincitesToggle from "../library/PincitesToggle";
import PincitesIconButton from "../library/PincitesIconButton";
import { Margin } from "../library/Margin";
import PincitesText, { PincitesTextType } from "../library/PincitesText";
import PincitesHeading, { PincitesHeadingType } from "../library/PincitesHeading";
import useSuggestedChanges from "../context/suggested_changes/useSuggestedChanges";
import useAnalyzeContract from "../context/analyze_contract/useAnalyzeContract";
import useClause from "../context/clause_context/useClause";
import SuggestChangeModal from "./SuggestChangeModal";

function ClauseDetails(): React.ReactElement {
  const { selectedClause, matchedClause } = useClause();
  const clauseMatchingType = getClauseMatchingType(matchedClause);
  const clauseInclusionType = selectedClause!.inclusionRequirement;

  const notes = selectedClause!.notes;
  return (
    <Stack>
      <Header clauseMatchingType={clauseMatchingType} clauseInclusionType={clauseInclusionType} />

      <TemplateText
        templateClauseText={selectedClause!.text}
        documentClauseText={matchedClause?.matchedDocumentText ?? ""}
        clauseMatchingType={clauseMatchingType}
        clauseInclusionType={clauseInclusionType}
      />

      <Guidance clauseMatchingType={clauseMatchingType} clauseInclusionType={clauseInclusionType} />
      {notes != null && notes.length > 0 && <Notes notes={notes} />}
    </Stack>
  );
}

function TemplateText({
  templateClauseText,
  documentClauseText,
  clauseMatchingType,
  clauseInclusionType,
}: {
  templateClauseText: string;
  documentClauseText: Maybe<string>;
  clauseMatchingType: TClauseMatchingType;
  clauseInclusionType: InclusionRequirement;
}): React.JSX.Element {
  const clauseDiff = documentClauseText != null ? FormattedDiffText(templateClauseText, documentClauseText) : null;

  // Show the diff view if there is a diff and the clause is optional or required
  const hasClauseDiff =
    clauseDiff != null &&
    getDoesInclusionTypeSupportDiffing(clauseInclusionType) &&
    getDoesClauseMatchingTypeSupportDiffing(clauseMatchingType);
  const [showDiff, setShowDiff] = useState(hasClauseDiff);

  // Whenever a new clause is selected we want to reset showDiff
  useEffect(() => {
    setShowDiff(hasClauseDiff);
  }, [hasClauseDiff, templateClauseText]);

  return (
    <Stack className={`template-text-container ${showDiff && clauseDiff && "template-text-container-diff"}`}>
      {showDiff && clauseDiff ? clauseDiff : <PincitesText>{templateClauseText}</PincitesText>}

      {hasClauseDiff ? (
        <div className="template-text-container-footer">
          <PincitesToggle isChecked={showDiff} setIsChecked={setShowDiff} label="Compare" />
          {showDiff === false && <PincitesCopyButton text={templateClauseText} />}
        </div>
      ) : (
        <PincitesCopyButton text={templateClauseText} />
      )}
    </Stack>
  );
}

function Header({
  clauseInclusionType,
  clauseMatchingType,
  clauseDiff,
}: {
  clauseInclusionType: InclusionRequirement;
  clauseMatchingType: TClauseMatchingType;
  clauseDiff?: React.ReactElement;
}): React.JSX.Element {
  let badgeType = getBadgeTypeFromMatchingType(clauseMatchingType);
  if (clauseInclusionType === InclusionRequirement.PROHIBITED) {
    badgeType = PincitesBadgeType.DANGER;
  }

  return (
    <Stack horizontal className={`clause-view-header ${!clauseDiff ? "clause-view-header-no-diff" : ""}`}>
      <PincitesText margin={[Margin.MARGIN_RIGHT_2]} textType={PincitesTextType.BOLD}>
        {clauseInclusionType === InclusionRequirement.PROHIBITED ? "Example language" : "Standard"}
      </PincitesText>
      <PincitesBadge
        label={getLabelForMatchingTypeAndInclusionRequirement(clauseMatchingType, clauseInclusionType)}
        badgeType={badgeType}
      />
      <SecondaryBadge inclusionRequirement={clauseInclusionType} />
    </Stack>
  );
}

function SecondaryBadge({ inclusionRequirement }: { inclusionRequirement: InclusionRequirement }): React.JSX.Element {
  if (!getShouldInclusionTypeBeDisplayedWithClauseHeader(inclusionRequirement)) {
    return <></>;
  }
  return (
    <PincitesBadge
      label={getLabelForInclusionRequirement(inclusionRequirement)}
      badgeType={PincitesBadgeType.GRAY_DARK}
    />
  );
}

function Guidance({
  clauseMatchingType,
  clauseInclusionType,
}: {
  clauseMatchingType: TClauseMatchingType;
  clauseInclusionType: InclusionRequirement;
}): React.JSX.Element {
  const [showSuggestGuidanceChangeModal, setShowSuggestGuidanceChangeModal] = useState<boolean>(false);
  const { selectedClause, matchedClause } = useClause();
  const { getSuggestedChange } = useSuggestedChanges();
  const { getUserDefinedGuidanceForClause, setUserDefinedGuidanceForClause } = useAnalyzeContract();

  const clauseID = selectedClause!.id;
  const doesClauseInclusionTypeSupportRedlines = getDoesInclusionTypeSupportDiffing(clauseInclusionType);
  const isClauseMatchingTypeChanged = clauseMatchingType === TClauseMatchingType.CHANGED;

  const userDefinedGuidance = getUserDefinedGuidanceForClause(clauseID);
  const selectedClauseGuidanceText = selectedClause!.guidance;
  const initialGuidanceText = userDefinedGuidance ?? selectedClauseGuidanceText ?? "";
  const [guidanceText, setGuidanceText] = useState<string>(initialGuidanceText);
  const [lastSuggestedGuidanceText, setLastSuggestedGuidanceText] = useState<Maybe<string>>(null);
  const [isEditingGuidance, setIsEditingGuidance] = useState<boolean>(false);

  const suggestedRedlinesForClause = getSuggestedChange(matchedClause?.clauseTemplate?.id);
  const documentClauseText = matchedClause?.matchedDocumentText ?? "";

  useEffect(() => {
    setIsEditingGuidance(false);
    // If there is user defined text for this clause, fetch that
    const userDefinedGuidance = getUserDefinedGuidanceForClause(clauseID);
    const initialTextToSet = userDefinedGuidance ?? selectedClauseGuidanceText ?? "";
    setGuidanceText(initialTextToSet);
  }, [selectedClauseGuidanceText]);

  return (
    <Stack>
      <PincitesHeading headingType={PincitesHeadingType.H1} margin={[Margin.MARGIN_BOTTOM_6, Margin.MARGIN_TOP_16]}>
        Guidance
      </PincitesHeading>
      {isEditingGuidance ? (
        <GuidanceTextBox text={guidanceText} setText={setGuidanceText} />
      ) : (
        <GuidanceTextComponent guidanceText={guidanceText} />
      )}
      <Stack horizontal horizontalAlign="space-between" style={{ marginTop: 4, width: "100%" }}>
        <Stack horizontal>
          {initialGuidanceText != guidanceText && lastSuggestedGuidanceText != guidanceText && (
            <PincitesActionButton
              onClick={() => {
                setShowSuggestGuidanceChangeModal(true);
              }}
              Icon={CommentAdd12Regular}
              label="SUGGEST CHANGE"
              margin={[Margin.MARGIN_HORZ_2]}
            />
          )}
          {doesClauseInclusionTypeSupportRedlines &&
            isClauseMatchingTypeChanged &&
            suggestedRedlinesForClause == null && (
              <SuggestRedlineButton
                guidanceText={guidanceText}
                clauseID={clauseID}
                documentClauseText={documentClauseText}
              />
            )}
        </Stack>
        <Stack horizontal>
          {isClauseMatchingTypeChanged && isEditingGuidance && suggestedRedlinesForClause != null && (
            <SuggestRedlineButton
              buttonLabel="Regenerate Redlines"
              guidanceText={guidanceText}
              clauseID={clauseID}
              documentClauseText={documentClauseText}
              onClick={() => {
                setUserDefinedGuidanceForClause(clauseID, guidanceText);
              }}
            />
          )}
          {isClauseMatchingTypeChanged && !isEditingGuidance && (
            <PincitesIconButton
              tooltip="Edit Guidance"
              Icon={Edit20Regular}
              onClick={() => {
                setIsEditingGuidance(true);
              }}
              style={{ marginTop: 4 }}
            />
          )}
          {isClauseMatchingTypeChanged && (isEditingGuidance || initialGuidanceText != guidanceText) && (
            <PincitesIconButton
              tooltip="Revert to playbook version"
              Icon={ArrowReset20Regular}
              onClick={() => {
                setGuidanceText(selectedClauseGuidanceText ?? "");
                setIsEditingGuidance(false);
              }}
              style={{ marginTop: 4 }}
            />
          )}
        </Stack>
      </Stack>
      {suggestedRedlinesForClause != null && <SuggestedRedlines suggestedChange={suggestedRedlinesForClause} />}
      <SuggestChangeModal
        isShown={showSuggestGuidanceChangeModal}
        onCloseModal={() => setShowSuggestGuidanceChangeModal(false)}
        onCreateSuggestion={() => {
          setLastSuggestedGuidanceText(guidanceText);
          setIsEditingGuidance(false);
        }}
        originalText={selectedClauseGuidanceText}
        newText={guidanceText}
      />
    </Stack>
  );
}

function Notes({ notes }: { notes: string }): React.JSX.Element {
  return (
    <Stack>
      <PincitesHeading headingType={PincitesHeadingType.H1} margin={[Margin.MARGIN_BOTTOM_6, Margin.MARGIN_TOP_16]}>
        Notes
      </PincitesHeading>
      <PincitesExpandableText text={notes} />
    </Stack>
  );
}

function SuggestedRedlines({ suggestedChange }: { suggestedChange: TSuggestedChange }): React.JSX.Element {
  const { matchedClause } = useClause();
  const { showErrorDialog } = useErrorHandling();
  const { token, signIn } = useAuthToken();
  // The reason this differs from buttonDisabled is that once clearing
  // up the redlines, we want the user to click the button
  const [applyButtonErrorMessage, setApplyButtonErrorMessage] =
    useState<Maybe<{ message: string; showRescanButton: boolean }>>(null);
  const nonNullToken: string = token!;
  const documentClauseText = matchedClause?.matchedDocumentText ?? "";

  useEffect(() => {
    const checkTextStatus = async () => {
      if (matchedClause != null) {
        const result = await getTextStatusForMatchedClause(matchedClause);
        const errorMessage = getErrorMessageFromClauseTextStatus(result);
        setApplyButtonErrorMessage(errorMessage);
      } else {
        setApplyButtonErrorMessage(null);
      }
    };

    checkTextStatus();
  }, [matchedClause]);

  const suggestedText = suggestedChange.suggestedText;
  const suggestedTextExplanation = suggestedChange.explanation;
  const suggestedTextIsEmpty = suggestedText === null || suggestedText === "";
  const clauseDiff = FormattedDiffText(documentClauseText, suggestedText);
  const hasSuggestedChange = !suggestedTextIsEmpty && clauseDiff != null;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const handleButtonClick = async () => {
    if (applyButtonErrorMessage != null) {
      const error = new Error("Please rescan before applying redlines.");
      let errorMsg = applyButtonErrorMessage.message;
      if (applyButtonErrorMessage.showRescanButton) {
        errorMsg = "Text has been modified since last scan. Please rescan before applying redlines.";
      }
      showErrorDialog({
        error,
        token: nonNullToken,
        errorMsg,
        skipLogging: true,
      });
      return;
    }
    setButtonDisabled(true);
    if (matchedClause != null) {
      await redlineAndCommentOnMatchedClause(matchedClause, suggestedText, suggestedTextExplanation);
    }
    await markSuggestedChangeApplied({ suggestedChange, signIn, token: nonNullToken, showErrorDialog });
  };
  return (
    <Stack>
      <Stack horizontal={false} className="suggested-redlines-section-container">
        <PincitesHeading headingType={PincitesHeadingType.H1} margin={[Margin.MARGIN_BOTTOM_6, Margin.MARGIN_TOP_16]}>
          Suggested Redlines
        </PincitesHeading>
        <PincitesText textType={PincitesTextType.SUB_TEXT}>Based on guidance</PincitesText>
      </Stack>
      <Stack className="template-text-container template-text-container-diff">
        {hasSuggestedChange ? clauseDiff : <NoSuggestedRedlines />}
        <Stack className="template-text-container-footer" horizontal>
          <Stack horizontal horizontalAlign="start">
            <Info12Regular className="info-tooltip-icon" />
            <PincitesText textType={PincitesTextType.SUB_TEXT}>Edit guidance to regenerate suggestions</PincitesText>
          </Stack>
          <Stack horizontal horizontalAlign="end">
            <PincitesThumbFeedbackButton
              subject="suggested redlines"
              likeOrDislike="Like"
              token={nonNullToken}
              suggestedText={suggestedText}
              signIn={signIn}
            />
            <PincitesThumbFeedbackButton
              subject="suggested redlines"
              likeOrDislike="Dislike"
              token={nonNullToken}
              suggestedText={suggestedText}
              signIn={signIn}
            />
          </Stack>
        </Stack>
      </Stack>
      {hasSuggestedChange && suggestedTextExplanation.length > 0 && (
        <Stack styles={{ root: { marginTop: 6 } }}>
          <PincitesText textType={PincitesTextType.SUB_TEXT}>Explanation comment for redlines</PincitesText>
          <Stack className="template-text-container">
            <PincitesText>{suggestedTextExplanation}</PincitesText>
            <Stack className="template-text-container-footer" horizontal horizontalAlign="end">
              <Stack horizontal horizontalAlign="end" className="feedback-buttons-container">
                <PincitesThumbFeedbackButton
                  subject="explanation comment"
                  likeOrDislike="Like"
                  token={nonNullToken}
                  suggestedText={suggestedTextExplanation}
                  signIn={signIn}
                />
                <PincitesThumbFeedbackButton
                  subject="explanation comment"
                  likeOrDislike="Dislike"
                  token={nonNullToken}
                  suggestedText={suggestedTextExplanation}
                  signIn={signIn}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}
      {hasSuggestedChange && (
        <Stack horizontal verticalAlign="center" horizontalAlign="start">
          <Stack horizontalAlign="end">
            <PincitesActionButton
              isDisabled={buttonDisabled}
              onClick={async () => {
                await handleButtonClick();
              }}
              Icon={CommentArrowRight12Regular}
              label="Apply redlines"
              margin={[Margin.MARGIN_TOP_4]}
            />

            {applyButtonErrorMessage && (
              <p className="disabled-redlines-message">{applyButtonErrorMessage.message ?? "Redlines already exist"}</p>
            )}
            {applyButtonErrorMessage && applyButtonErrorMessage.showRescanButton && (
              <RescanButton resetClauseViewOnClick={true} />
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

function GuidanceTextBox({ text, setText }: { text: string; setText: (arg: string) => void }): React.JSX.Element {
  return (
    <textarea
      title="guidance-text-box"
      className="guidance-text-box"
      rows={4}
      value={text}
      onChange={(e) => setText(e.target.value)}
    />
  );
}

function SuggestRedlineButton({
  documentClauseText,
  clauseID,
  guidanceText,
  buttonLabel,
  onClick,
}: {
  buttonLabel?: string;
  guidanceText: string;
  documentClauseText: string;
  clauseID: string;
  onClick?: () => void;
}): React.JSX.Element {
  const [isFetchingSuggestion, setIsFetchingSuggestion] = useState(false);
  const isUsingOfficeOnline = getIsUsingOfficeOnline();
  const { showErrorDialog } = useErrorHandling();
  const { token, signIn } = useAuthToken();
  const nonNullToken: string = token!;
  const { addSuggestedChange } = useSuggestedChanges();

  const handleButtonClick = async () => {
    setIsFetchingSuggestion(true);
    const suggestedChange = await suggestClauseChanges({
      signIn,
      documentClauseText,
      guidanceText,
      clauseTemplateID: clauseID,
      token: nonNullToken, // Assuming `token` is of type `string` as retrieved from `useAuthToken()`
      showErrorDialog, // Include the required `showErrorDialog` parameter here
      onError: (err) => {
        showErrorDialog({
          error: err,
          token: nonNullToken,
          errorMsg: "Something went wrong, and we couldn't complete the suggested redlines for you. Please try again.",
        });
        setIsFetchingSuggestion(false);
      },
    });
    setIsFetchingSuggestion(false);
    if (suggestedChange != null) {
      addSuggestedChange(suggestedChange);
    }
    onClick && onClick();
  };

  return (
    <Stack horizontal={false} horizontalAlign="start">
      <PincitesActionButton
        isDisabled={isFetchingSuggestion || isUsingOfficeOnline}
        onClick={async () => {
          await handleButtonClick();
        }}
        showSpinner={isFetchingSuggestion}
        Icon={CommentAdd12Regular}
        label={buttonLabel ?? "Suggest Redlines"}
      />
      {isUsingOfficeOnline && (
        <div className="redlines-unsupported">
          <Warning12Regular className="subtext" />
          <PincitesText textType={PincitesTextType.SUB_TEXT}>
            This version of Office does not support redlining
          </PincitesText>
        </div>
      )}
      {isFetchingSuggestion ? (
        <PincitesText textType={PincitesTextType.SUB_TEXT}>Fetching suggested redlines...</PincitesText>
      ) : null}
    </Stack>
  );
}

function getErrorMessageFromClauseTextStatus(status: {
  textModified: boolean;
  redlinesExistInText: boolean;
}): Maybe<{ message: string; showRescanButton: boolean }> {
  if (status.textModified) {
    return {
      message: "Text has been modified since last scan. Please rescan before applying redlines.",
      showRescanButton: false,
    };
  } else {
    return null;
  }
}

function getClauseMatchingType(matchedClause: Maybe<TMatchedClause>): TClauseMatchingType {
  if (matchedClause == null) {
    return TClauseMatchingType.MISSING;
  }

  if (matchedClause.isStandardText) {
    return TClauseMatchingType.STANDARD;
  } else {
    return TClauseMatchingType.CHANGED;
  }
}

function NoSuggestedRedlines(): React.JSX.Element {
  return <span className="placeholder-text">No redline suggestions</span>;
}

export default ClauseDetails;
