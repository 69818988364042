import React from "react";
import { Toggle } from "@fluentui/react";

export default function PincitesToggle({
  isChecked,
  setIsChecked,
  label,
}: {
  label: string;
  isChecked: boolean;
  setIsChecked: (arg: boolean) => void;
}): React.JSX.Element {
  return (
    <Toggle
      className={`toggle-diff-redlines`}
      inlineLabel
      label={label}
      onText=" "
      offText=" "
      checked={isChecked}
      onChange={(_event, checked: boolean = false) => setIsChecked(checked)}
      style={{ transform: "scale(0.6)" }}
      styles={{
        label: { fontSize: 20, marginRight: 3, paddingBottom: 7, transform: "scale(1.2)" },
        text: { display: "none" },
      }}
    />
  );
}
