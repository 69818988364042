import { Maybe, TDiffEntry } from "../types/ClauseTypes";
import * as Diff from "diff";
import React from "react";

export function FormattedDiffText(existingText: string, newText: string): Maybe<React.JSX.Element> {
  const groupedDiffList = getHumanReadableDiff(existingText, newText);
  const diff: (React.JSX.Element | string)[] = [];

  // Iterate through the diff list adding to the diff
  groupedDiffList.forEach((part, index) => {
    if (part.added) {
      diff.push(
        <span key={`added,${index},${part.value}`} className="diff-text-added">
          {part.value}
        </span>
      );
    }
    if (part.removed) {
      diff.push(
        <span key={`removed,${index},${part.value}`} className="diff-text-removed">
          {part.value}
        </span>
      );
    }
    if (!part.added && !part.removed) {
      diff.push(part.value);
    }
  });

  // Determine if there are actual changes
  const hasChanges = groupedDiffList.some((part) => part.added || part.removed);

  // Turn it into a react element
  return hasChanges ? <span>{diff}</span> : null;
}

export function getHumanReadableDiff(existingText: string, incomingText: string): Array<TDiffEntry> {
  const diffList = Diff.diffWordsWithSpace(existingText, incomingText);
  const convertedDiffList = diffList.map((change) => {
    return {
      count: change.count ?? 0,
      added: change.added,
      removed: change.removed,
      value: change.value,
    };
  });
  return groupDiffWords(convertedDiffList);
}

// Combine entries in a diff list that are sequences of one-word additions followed by one word removals
// followed by an unchanged space or other punctuation character. This makes our redline look more like
// a human being's redline.
// Without this the redline between "Some random characters were inserted." and "Totally different words were inserted."
// would look like
// <del>Some</del><ins>Totally</ins> <del>random</del><ins>different</ins> <del>characters</del><ins>words</ins> were inserted.
// With this it looks like
// <del>Some random characters</del><ins>Totally different words</ins> were inserted.
function groupDiffWords(diffList: Array<TDiffEntry>): Array<TDiffEntry> {
  let groupedDiffList = [];
  let entriesToGroup = [];

  function combineEntries(groupOfEntries: Array<TDiffEntry>): Array<TDiffEntry> {
    var additionEntry = { count: 0, added: true, value: "" };
    var removalEntry = { count: 0, removed: true, value: "" };
    var leadingUnchangedEntry = { count: 0, value: "" };
    var trailingUnchangedEntry = { count: 0, value: "" };

    // Combine the entries in groupOfEntries
    groupOfEntries.forEach((e) => {
      if (!e.removed) {
        additionEntry.count = additionEntry.count + e.count;
        additionEntry.value = additionEntry.value + e.value;
      }
      if (!e.added) {
        removalEntry.count = removalEntry.count + e.count;
        removalEntry.value = removalEntry.value + e.value;
      }
    });

    // Get the overlap at the end of the addition and the end of the removal
    var overlap = 0;
    while (
      overlap < Math.min(additionEntry.value.length, removalEntry.value.length) &&
      additionEntry.value[additionEntry.value.length - overlap - 1] ===
        removalEntry.value[removalEntry.value.length - overlap - 1]
    ) {
      overlap++;
    }
    var overlapString = additionEntry.value.substring(additionEntry.value.length - overlap);

    // Start the overlap string at the first non-alphanumeric character (if there is one)
    overlapString = overlapString.replace(/^[a-zA-Z0-9]+/, "");

    // Create a trailing unchanged entry from the overlap string
    if (overlapString.length > 0) {
      removalEntry.value = removalEntry.value.substring(0, removalEntry.value.length - overlapString.length);
      removalEntry.count = Diff.diffWordsWithSpace(removalEntry.value, "")[0].count ?? 0;
      additionEntry.value = additionEntry.value.substring(0, additionEntry.value.length - overlapString.length);
      additionEntry.count = Diff.diffWordsWithSpace(additionEntry.value, "")[0].count ?? 0;
      trailingUnchangedEntry.value = overlapString;
      trailingUnchangedEntry.count = Diff.diffWordsWithSpace(trailingUnchangedEntry.value, "")[0].count ?? 0;
    }

    // Get the overlap at the start of the addition and the start of the removal
    overlap = 0;
    while (
      overlap < Math.min(additionEntry.value.length, removalEntry.value.length) &&
      additionEntry.value[overlap] === removalEntry.value[overlap]
    ) {
      overlap++;
    }
    overlapString = additionEntry.value.substring(0, overlap);

    // End the overlap string at the last non-alphanumeric character (if there is one)
    overlapString = overlapString.replace(/[a-zA-Z0-9]+$/, "");

    // Create a leading unchanged entry from the overlap string
    if (overlapString.length > 0) {
      removalEntry.value = removalEntry.value.substring(overlapString.length);
      removalEntry.count = Diff.diffWordsWithSpace(removalEntry.value, "")[0].count ?? 0;
      additionEntry.value = additionEntry.value.substring(overlapString.length);
      additionEntry.count = Diff.diffWordsWithSpace(additionEntry.value, "")[0].count ?? 0;
      leadingUnchangedEntry.value = overlapString;
      leadingUnchangedEntry.count = Diff.diffWordsWithSpace(leadingUnchangedEntry.value, "")[0].count ?? 0;
    }

    // Return an array of the combined entries (if they are non-zero)
    var groupedEntries = [];
    if (leadingUnchangedEntry.count > 0) {
      groupedEntries.push(leadingUnchangedEntry);
    }
    if (removalEntry.count > 0) {
      groupedEntries.push(removalEntry);
    }
    if (additionEntry.count > 0) {
      groupedEntries.push(additionEntry);
    }
    if (trailingUnchangedEntry.count > 0) {
      groupedEntries.push(trailingUnchangedEntry);
    }
    return groupedEntries;
  }

  for (let i = 0; i < diffList.length; i++) {
    const entry = diffList[i];

    // If we don't have a combined entry, check if this is a candidate for the start of a combination
    if (entriesToGroup.length == 0) {
      if (!entry.added && !entry.removed) {
        // Never start a combination with an unchanged entry
        groupedDiffList.push(entry);
        continue;
      }
      if (i === diffList.length - 1) {
        // Never start a combination with the final entry
        groupedDiffList.push(entry);
        continue;
      }
      entriesToGroup.push(entry);
      continue;
    }

    // Long unchanged entries always break a combination, as does the final entry
    if ((!entry.added && !entry.removed && entry.count > 3) || i === diffList.length - 1) {
      // Combine the entries in entriesToGroup and add them to groupedDiffList
      var combinedEntries = combineEntries(entriesToGroup);

      // If the last entry in groupedDiffList is the same type as the first entry in combinedEntries, combine them
      if (
        groupedDiffList.length > 0 &&
        combinedEntries.length > 0 &&
        groupedDiffList[groupedDiffList.length - 1].added == combinedEntries[0].added &&
        groupedDiffList[groupedDiffList.length - 1].removed == combinedEntries[0].removed
      ) {
        const lastEntry: TDiffEntry = groupedDiffList.pop() as TDiffEntry;
        combinedEntries[0].value = lastEntry.value + combinedEntries[0].value;
        combinedEntries[0].count = lastEntry.count + combinedEntries[0].count;
      }

      groupedDiffList.push(...combinedEntries);

      // Reset entriesToGroup
      entriesToGroup = [];

      // If the last entry in groupedDiffList is the same type as this entry, combine them
      if (
        groupedDiffList.length > 0 &&
        groupedDiffList[groupedDiffList.length - 1].added == entry.added &&
        groupedDiffList[groupedDiffList.length - 1].removed == entry.removed
      ) {
        const lastEntry: TDiffEntry = groupedDiffList.pop() as TDiffEntry;
        entry.value = lastEntry.value + entry.value;
        entry.count = lastEntry.count + entry.count;
      }

      // Add the current entry to groupedDiffList
      groupedDiffList.push(entry);
      continue;
    }

    // If we get here, we have a candidate for a combination
    entriesToGroup.push(entry);
  }

  return groupedDiffList;
}
