import { useState, useEffect } from "react";
import { Maybe } from "../types/ClauseTypes";

type useNavigatorPermissionsInterface = {
  status: Maybe<PermissionState>;
};

export default function useNavigatorPermissions({
  name,
  configuration,
}: {
  name: PermissionName;
  configuration?: object;
}): useNavigatorPermissionsInterface {
  const [permitted, setPermitted] = useState<Maybe<PermissionState>>(null);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (window?.navigator?.permissions) {
      // eslint-disable-next-line no-undef
      window.navigator.permissions
        .query({ name, ...configuration })
        .then((status) => {
          setPermitted(status.state);
        })
        .catch((reason) => {
          if (reason instanceof TypeError) {
            // If the browser doesn't support the passed permission type we'll
            // end up here. In that case, we assume the permission is granted
            // (since it's not a known permission type).
            setPermitted("granted");
          } else {
            throw reason;
          }
        });
    }
  }, [name, configuration]);

  return { status: permitted };
}
