import React, { useState } from "react";
import { IconButton, Stack, Text } from "@fluentui/react";
import { TClauseTextAlternative } from "../types/ClauseTypes";
import { getLabelForPreferenceLevel, getColorForPreferenceLevel } from "../types/PreferenceLevel";
import AlternativeClauseDetails from "./AlternativeClauseDetails";
import PincitesText from "../library/PincitesText";

interface ClausePreferenceLevelDetailsProps {
  alternativeClause: TClauseTextAlternative;
}

const ClausePreferenceLevelDetails: React.FC<ClausePreferenceLevelDetailsProps> = ({ alternativeClause }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Stack tokens={{ childrenGap: 10 }} className="clause-details-alternative-language-section">
      <Stack horizontal verticalAlign="center" onClick={() => setIsOpen(!isOpen)}>
        <IconButton
          iconProps={{ iconName: isOpen ? "ChevronUp" : "ChevronDown" }}
          title="Expand/Collapse"
          ariaLabel="Expand/Collapse"
        />
        <Stack horizontal>
          <div
            className="preference-level-circle-badge"
            style={{
              backgroundColor: getColorForPreferenceLevel(alternativeClause.preferenceLevel),
            }}
          />
          <PincitesText>{getLabelForPreferenceLevel(alternativeClause.preferenceLevel)}</PincitesText>
        </Stack>
      </Stack>
      {isOpen && (
        <AlternativeClauseDetails
          alternativeLanguageText={alternativeClause.text}
          alternativeLanguageGuidance={alternativeClause.comment}
        />
      )}
    </Stack>
  );
};

export default ClausePreferenceLevelDetails;
