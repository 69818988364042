export enum PreferenceLevel {
  PREFERRED = "preferred",
  ACCEPTABLE = "acceptable",
  DISCOURAGED = "discouraged",
  UNACCEPTABLE = "unacceptable",
}

export function getLabelForPreferenceLevel(preferredLevel: PreferenceLevel): string {
  switch (preferredLevel) {
    case PreferenceLevel.PREFERRED:
      return "Preferred language";
    case PreferenceLevel.ACCEPTABLE:
      return "Acceptable language";
    case PreferenceLevel.DISCOURAGED:
      return "Discouraged language";
    case PreferenceLevel.UNACCEPTABLE:
      return "Unacceptable language";
  }
}

export function getColorForPreferenceLevel(preferredLevel: PreferenceLevel): string {
  switch (preferredLevel) {
    case PreferenceLevel.PREFERRED:
      return "#34D399";
    case PreferenceLevel.ACCEPTABLE:
      return "#60A6FA";
    case PreferenceLevel.DISCOURAGED:
      return "#FBBF23";
    case PreferenceLevel.UNACCEPTABLE:
      return "#F97171";
  }
}
