import React, { useState, PropsWithChildren } from "react";
import SuggestedChangesContext from "./SuggestedChangesContext";
import { Maybe, TSuggestedChange } from "../../types/ClauseTypes";

const SuggestedChangesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [suggestedChanges, setSuggestedChanges] = useState<TSuggestedChange[]>([]);

  const clearSuggestedChanges = (): void => {
    setSuggestedChanges([]);
  };
  const getSuggestedChange = (clauseTemplateID: Maybe<string>): Maybe<TSuggestedChange> => {
    if (clauseTemplateID == null) {
      return null;
    }
    return suggestedChanges.find((suggestedChange) => suggestedChange.clauseTemplateId === clauseTemplateID);
  };
  const addSuggestedChange = (suggestedChange: TSuggestedChange): void => {
    setSuggestedChanges([suggestedChange, ...suggestedChanges]);
  };

  return (
    <SuggestedChangesContext.Provider value={{ clearSuggestedChanges, getSuggestedChange, addSuggestedChange }}>
      {children}
    </SuggestedChangesContext.Provider>
  );
};

export default SuggestedChangesProvider;
