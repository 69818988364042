import { IconButton } from "@fluentui/react";
import React, { useState } from "react";
import useNavigatorPermissions from "../utils/useNavigatorPermissions";
import PincitesText, { PincitesTextType } from "./PincitesText";
import { Margin } from "./Margin";

export default function PincitesCopyButton({ text }: { text: string }): React.JSX.Element {
  const [showCopiedText, setShowCopiedText] = useState(false);
  // Invoke the useNavigatorPermissions hook and pass the required parameters
  const canCopyToClipboard = getCanCopyToClipboard();

  if (!canCopyToClipboard) {
    return <></>;
  }

  // Function to copy the template text to the clipboard
  const copyTemplateTextToClipboard = () => {
    if (canCopyToClipboard) {
      // eslint-disable-next-line no-undef
      navigator.clipboard.writeText(text);
      setShowCopiedText(true);
      // eslint-disable-next-line no-undef
      setTimeout(() => {
        setShowCopiedText(false);
      }, 1000); // 1 second (1000 milliseconds) to revert back to "Copy" after "Copied"
    }
  };
  // Copy Button or "Copied" Text
  return showCopiedText ? (
    <PincitesText
      customStyle={{ right: 0, bottom: 0, position: "absolute" }}
      textType={PincitesTextType.SUB_TEXT}
      margin={[Margin.MARGIN_BOTTOM_6, Margin.MARGIN_RIGHT_6]}
    >
      Copied
    </PincitesText>
  ) : (
    <IconButton
      iconProps={{ iconName: "Copy" }}
      title="Copy"
      ariaLabel="Copy"
      onClick={copyTemplateTextToClipboard}
      className="copy-template-text-button"
      style={{ padding: 0, margin: 0 }}
    />
  );
}

function getCanCopyToClipboard(): boolean {
  return useNavigatorPermissions({ name: "clipboard-write" as PermissionName }).status === "granted";
}
