import React, { useEffect, useRef, useState } from "react";
import { PrimaryButton, Modal, Spinner, Stack, DefaultButton, SpinnerSize } from "@fluentui/react";
import { TPlaybook } from "../types/ClauseTypes";
import { analyzeDocument, getPlaybooks } from "../utils/apiUtils";
import PlaybookSelectionDropdown from "./PlaybookSelectionDropdown";
import { useAuthToken } from "../auth/useAuthToken";
import useErrorHandling from "../error_handling/useErrorHandling";
import { getDocumentContent, getDocumentFilename, setUpJumpToClauseTemplate } from "../utils/wordUtils";
import useAnalyzeContract from "../context/analyze_contract/useAnalyzeContract";
import PincitesText from "../library/PincitesText";
import { Margin } from "../library/Margin";
import useClause from "../context/clause_context/useClause";
import PincitesHeading, { PincitesHeadingType } from "../library/PincitesHeading";

interface ChoosePlaybookModalProps {
  isOpen: boolean;
  onDismiss: () => void;
}

const ChoosePlaybookModal: React.FC<ChoosePlaybookModalProps> = ({ isOpen, onDismiss }) => {
  const [playbooks, setPlaybooks] = useState<TPlaybook[]>([]);
  const { token, signIn } = useAuthToken();
  const { showErrorDialog } = useErrorHandling();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showNoPlaybooksErrorMessage, setShowNoPlaybooksErrorMessage] = useState(false);
  const { navigateToClauseList, setClauseResult } = useClause();
  // Create a ref such that clicking outside the dropdown will close the dropdown
  const containerRef = useRef<HTMLDivElement>(null);
  const nonNullToken: string = token!;
  const { setCurrentPlaybook, currentPlaybook } = useAnalyzeContract();

  useEffect(() => {
    const fetchPlaybooks = async () => {
      try {
        const playbooks = await getPlaybooks({ token: nonNullToken, signIn, showErrorDialog });
        setPlaybooks(playbooks);
      } catch {
        setShowNoPlaybooksErrorMessage(true);
      }
    };
    fetchPlaybooks();
  }, [token, showErrorDialog]);

  const PlaybookSelector = () => (
    <div ref={containerRef}>
      <PlaybookSelectionDropdown
        containerRef={containerRef}
        placeholder="Choose playbook"
        options={playbooks.map((playbook) => ({
          key: playbook.id.toString(),
          text: playbook.name,
          description: playbook.description,
        }))}
        selectedKey={currentPlaybook?.id}
        onChange={(newPlaybookID) => {
          const newPlaybook = playbooks.find((playbook) => playbook.id === newPlaybookID);
          if (newPlaybook == null) {
            throw new Error(`Could not find playbook with id ${newPlaybookID}`);
          }
          setCurrentPlaybook(newPlaybook);
        }}
        isOpen={isDropdownOpen}
        onToggleOpen={() => setIsDropdownOpen(!isDropdownOpen)}
      />
    </div>
  );

  const StartReviewButton = () => {
    const { setAnalyzedContract } = useAnalyzeContract();
    const [isAnalyzing, setIsAnalyzing] = React.useState<boolean>(false);
    const { showErrorDialog } = useErrorHandling();

    return (
      <Stack>
        <PrimaryButton
          className="modal-button modal-button-primary"
          disabled={isAnalyzing || currentPlaybook == null}
          onClick={async () => {
            setIsAnalyzing(true);
            const documentBody = await getDocumentContent();
            const analyzedContract = await analyzeDocument({
              signIn,
              body: documentBody,
              filename: getDocumentFilename(),
              playbookID: currentPlaybook?.id ?? "",
              token: nonNullToken,
              showErrorDialog,
              onError: () => {
                setIsAnalyzing(false);
                setShowErrorMessage(true);
              },
            });
            if (analyzedContract != null) {
              await setUpJumpToClauseTemplate(analyzedContract, setClauseResult);
            }
            setIsAnalyzing(false);
            onDismiss();
            navigateToClauseList();
            if (analyzedContract != null) {
              setAnalyzedContract(analyzedContract);
            }
          }}
        >
          {isAnalyzing ? <Spinner size={SpinnerSize.small} /> : "Apply Playbook"}
        </PrimaryButton>
      </Stack>
    );
  };

  if (showNoPlaybooksErrorMessage) {
    return <NoPlaybooksExistModal isOpen={isOpen} onDismiss={onDismiss} />;
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} isBlocking={false} containerClassName="modal-form-container">
      <div className={`modal-content ${isDropdownOpen ? "open" : ""}`}>
        <SelectPlaybookText />
        <PlaybookSelector />
        {currentPlaybook && <PlaybookDescriptionText playbookDescriptionText={currentPlaybook.description} />}
        <ErrorMessage isShown={showErrorMessage} />
        <Stack className="modal-footer select-playbook" horizontalAlign="center" horizontal>
          <DefaultButton className="modal-button modal-button-secondary" text="Cancel" onClick={onDismiss} />
          <StartReviewButton />
        </Stack>
      </div>
    </Modal>
  );
};

function SelectPlaybookText(): React.JSX.Element {
  return (
    <>
      <PincitesHeading
        headingType={PincitesHeadingType.APP_TITLE}
        margin={[Margin.MARGIN_BOTTOM_2, Margin.MARGIN_TOP_16]}
      >
        Choose your playbook
      </PincitesHeading>
      <p className="text-description">Evaluate your document&apos;s compliance using a playbook.</p>
    </>
  );
}

function PlaybookDescriptionText({ playbookDescriptionText }: { playbookDescriptionText: string }): React.JSX.Element {
  return (
    <div className="playbook-description-container">
      <PincitesHeading headingType={PincitesHeadingType.H2} margin={[Margin.MARGIN_BOTTOM_2, Margin.MARGIN_TOP_16]}>
        About this playbook
      </PincitesHeading>
      <p className="text-description">{playbookDescriptionText}</p>
    </div>
  );
}

function NoPlaybooksExistModal({ isOpen, onDismiss }: { isOpen: boolean; onDismiss: () => void }): React.JSX.Element {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} isBlocking={false}>
      <Stack horizontalAlign="center">
        <PincitesText margin={[Margin.MARGIN_TOP_8]}>Error loading playbooks.</PincitesText>
        <PincitesText>Please try again later.</PincitesText>
      </Stack>
    </Modal>
  );
}

function ErrorMessage({ isShown }: { isShown: boolean }): React.JSX.Element {
  if (!isShown) {
    return <></>;
  }
  return (
    <Stack horizontalAlign="center" horizontal tokens={{ childrenGap: 5 }}>
      <PincitesText customStyle={{ color: "red", fontWeight: "bold" }}>
        We couldn&apos;t get your playbooks
      </PincitesText>
      <PincitesText> Try again below:</PincitesText>
    </Stack>
  );
}

export default ChoosePlaybookModal;
