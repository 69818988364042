import React from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { RescanButton, RescanButtonSize } from "../analyze/RescanButton";

interface ErrorDialogProps {
  errorMsg: string;
  hideDialog: () => void;
  showRescanButton: boolean;
}

function ErrorDialog({ errorMsg, hideDialog }: ErrorDialogProps) {
  return (
    <Dialog
      hidden={false}
      onDismiss={hideDialog}
      dialogContentProps={{
        type: DialogType.normal,
        title: "Oops!",
        subText: errorMsg,
        className: "error-dialog",
      }}
      modalProps={{
        isBlocking: true,
      }}
    >
      <DialogFooter>
        <RescanButton
          resetClauseViewOnClick={true}
          buttonSize={RescanButtonSize.STANDARD}
          onScanCompleted={() => hideDialog()}
        />
        <DefaultButton className="error-dialog-button" onClick={hideDialog} text="OK" />
      </DialogFooter>
    </Dialog>
  );
}

export default ErrorDialog;
