import React from "react";
export default function PincitesLink({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}): React.JSX.Element {
  return <a href={href}>{children}</a>;
}
