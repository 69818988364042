import React, { useState } from "react";
import { Icon, Stack } from "@fluentui/react";
import ClauseResults from "../Clauses/ClauseResults";
import useAnalyzeContract from "../context/analyze_contract/useAnalyzeContract";
import PincitesText, { PincitesTextType } from "../library/PincitesText";
import ClauseProvider from "../context/clause_context/ClauseProvider";

function AnalyzedDocumentResults(): React.JSX.Element {
  const { analyzedContract } = useAnalyzeContract();
  const analyzedClausesExist =
    (analyzedContract?.matchedClauses ?? []).length > 0 || (analyzedContract?.missingClauses ?? []).length > 0;
  return (
    <Stack className="results-container">
      {!analyzedClausesExist && <NotEnoughContent />}
      <ClauseResults />
    </Stack>
  );
}

function NotEnoughContent(): React.JSX.Element {
  const [isDismissed, setIsDismissed] = useState<boolean>(false);
  if (isDismissed) {
    return <></>;
  }
  return (
    <Stack className="not-enough-content-box" style={{ position: "relative" }}>
      <PincitesText textType={PincitesTextType.BOLD}>
        <Icon iconName="Warning" style={{ marginRight: 5, color: "blue" }} />
        Not enough content
      </PincitesText>
      <PincitesText>
        No matching clauses found from your playbook. Add more to your document, then click “Re-check”.
      </PincitesText>
      <div className="cancel-icon-container">
        <Icon iconName="Cancel" onClick={() => setIsDismissed(true)} style={{ cursor: "pointer", marginRight: 10 }} />
      </div>
    </Stack>
  );
}

export default AnalyzedDocumentResults;
