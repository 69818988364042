import React from "react";
import { Stack } from "@fluentui/react";
import PincitesExpandableText from "../library/PincitesExpandableText";
import PincitesText from "../library/PincitesText";

interface GuidanceTextProps {
  guidanceText: string | undefined;
}

const GuidanceText: React.FC<GuidanceTextProps> = ({ guidanceText }) => {
  const noGuidanceString = <span className="placeholder-text">No guidance provided</span>;

  return (
    <Stack>
      <PincitesText>{guidanceText ? <PincitesExpandableText text={guidanceText} /> : noGuidanceString}</PincitesText>
    </Stack>
  );
};

export default GuidanceText;
