import { useContext } from "react";
import ClauseContext from "./ClauseContext";

const useClause = () => {
  const context = useContext(ClauseContext);
  if (context === undefined) {
    throw new Error("useClause must be used within an ClauseContextProvider");
  }
  return context;
};

export default useClause;
