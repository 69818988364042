import { useContext } from "react";
import AnalyzeContractContext from "./AnalyzeContractContext";

const useAnalyzeContract = () => {
  const context = useContext(AnalyzeContractContext);
  if (context === undefined) {
    throw new Error("useAnalyzeContract must be used within an AnalyzeContractProvider");
  }
  return context;
};

export default useAnalyzeContract;
