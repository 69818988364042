import { useContext } from "react";
import SuggestedChangesContext from "./SuggestedChangesContext";

const useSuggestedChanges = () => {
  const context = useContext(SuggestedChangesContext);
  if (context === undefined) {
    throw new Error("useSuggestedChanges must be used within an SuggestedChangesProvider");
  }
  return context;
};

export default useSuggestedChanges;
