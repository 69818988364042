import React from "react";
import { Stack } from "@fluentui/react";
import GuidanceTextComponent from "./GuidanceTextComponent";
import PincitesText from "../library/PincitesText";
import { Margin } from "../library/Margin";
import PincitesCopyButton from "../library/PincitesCopyButton";
import PincitesHeading, { PincitesHeadingType } from "../library/PincitesHeading";

function AlternativeClauseDetails({
  alternativeLanguageText,
  alternativeLanguageGuidance,
}: {
  alternativeLanguageText: string;
  alternativeLanguageGuidance: string;
}): React.ReactElement {
  return (
    <Stack>
      <Stack className={`template-text-container`}>
        <PincitesText>{alternativeLanguageText}</PincitesText>
        <PincitesCopyButton text={alternativeLanguageText} />
      </Stack>
      <PincitesHeading headingType={PincitesHeadingType.H2} margin={[Margin.MARGIN_BOTTOM_6, Margin.MARGIN_TOP_16]}>
        Comment
      </PincitesHeading>
      <GuidanceTextComponent guidanceText={alternativeLanguageGuidance} />
    </Stack>
  );
}

export default AlternativeClauseDetails;
