import * as Sentry from "@sentry/react";
import jwt_decode from "jwt-decode";
import { DecodedMicrosoftToken } from "../types/Microsoft";
import { Maybe } from "../types/ClauseTypes";
import axios from "axios";
import { getIsDevEnvironment } from "../utils/debuggingUtils";

export function initializeSentry() {
  if (!getIsDevEnvironment()) {
    Sentry.init({
      dsn: "https://5454913b04cd65bb47cbaef2e71b0213@o4505440485507072.ingest.sentry.io/4505609335603200",
    });
  }
}

export async function tagSentryWithUserAndVersion({ token }: { token: string }): Promise<void> {
  try {
    if (getIsDevEnvironment()) {
      return;
    }
    const version = await getPluginVersion();
    const decodedToken: DecodedMicrosoftToken = jwt_decode(token);
    Sentry.configureScope((scope) => {
      scope.setTag("user_name", decodedToken.name);
      scope.setTag("user_email", decodedToken.email);
      if (version) {
        scope.setTag("version", version);
      }
    });
  } catch {
    // eslint-disable-next-line no-undef
    console.error("Error tagging sentry");
  }
}

export function logErrorForAuthenticatedUser({
  token,
  error,
  metadata,
}: {
  token: string;
  error: Error;
  metadata?: Record<string, any>;
}): void {
  const decodedToken: DecodedMicrosoftToken = jwt_decode(token);
  const loggingData = {
    user_name: decodedToken.name,
    user_email: decodedToken.email,
    payload: metadata,
  };
  Sentry.captureException(error, { extra: loggingData });
}

export function logErrorForUnauthenticatedUser({
  error,
  metadata,
}: {
  error: Error;
  metadata?: Record<string, any>;
}): void {
  if (!getIsDevEnvironment()) {
    Sentry.captureException(error, { extra: metadata });
  }
}

function getPluginVersion(): Promise<Maybe<string>> {
  return axios
    .get("/version.txt")
    .then((response) => response.data as string)
    .catch(() => {
      return null;
    });
}
