import { PincitesBadgeType } from "../library/PincitesBadge";
import { InclusionRequirement } from "./InclusionRequirement";

export enum TClauseMatchingType {
  CHANGED = "CHANGED",
  MISSING = "MISSING",
  STANDARD = "STANDARD",
}

export function getDoesClauseMatchingTypeSupportDiffing(matchingType: TClauseMatchingType): boolean {
  switch (matchingType) {
    case TClauseMatchingType.CHANGED:
      return true;
    case TClauseMatchingType.STANDARD:
    case TClauseMatchingType.MISSING:
      return false;
  }
}

export function getBadgeTypeFromMatchingType(matchingType: TClauseMatchingType): PincitesBadgeType {
  switch (matchingType) {
    case TClauseMatchingType.CHANGED:
      return PincitesBadgeType.ORANGE;
    case TClauseMatchingType.STANDARD:
      return PincitesBadgeType.GREEN;
    case TClauseMatchingType.MISSING:
      return PincitesBadgeType.GRAY;
  }
}

export function getLabelForMatchingTypeAndInclusionRequirement(
  matchingType: TClauseMatchingType,
  inclusionRequirement?: InclusionRequirement
): string {
  if (inclusionRequirement === InclusionRequirement.PROHIBITED) {
    return "Prohibited";
  }
  switch (matchingType) {
    case TClauseMatchingType.CHANGED:
      return "Changed";
    case TClauseMatchingType.MISSING:
      return "Missing";
    case TClauseMatchingType.STANDARD:
      return "Standard";
  }
}
