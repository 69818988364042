import React, { useState } from "react";
import { useAuthToken } from "../auth/useAuthToken";
import useErrorHandling from "../error_handling/useErrorHandling";
import { DefaultButton, Spinner, SpinnerSize } from "@fluentui/react";
import { getDocumentContent, getDocumentFilename, setUpJumpToClauseTemplate } from "../utils/wordUtils";
import { analyzeDocument } from "../utils/apiUtils";
import useAnalyzeContract from "../context/analyze_contract/useAnalyzeContract";
import useSuggestedChanges from "../context/suggested_changes/useSuggestedChanges";
import useClause from "../context/clause_context/useClause";

export enum RescanButtonSize {
  SMALL = "SMALL",
  STANDARD = "STANDARD",
}

export function RescanButton({
  resetClauseViewOnClick,
  buttonSize = RescanButtonSize.SMALL,
  onScanCompleted,
}: {
  resetClauseViewOnClick: boolean;
  buttonSize?: RescanButtonSize;
  onScanCompleted?: () => void;
}): React.JSX.Element {
  const { navigateToClauseList, setClauseResult } = useClause();
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const { token, signIn } = useAuthToken();
  const { showErrorDialog } = useErrorHandling();
  const nonNullToken: string = token!;
  const { setAnalyzedContract, currentPlaybook } = useAnalyzeContract();
  const { clearSuggestedChanges } = useSuggestedChanges();

  return (
    <DefaultButton
      onClick={async () => {
        setIsAnalyzing(true);
        const documentBody = await getDocumentContent();
        const analyzedContract = await analyzeDocument({
          signIn,
          body: documentBody,
          filename: getDocumentFilename(),
          playbookID: currentPlaybook!.id,
          token: nonNullToken,
          showErrorDialog,
          onError: () => {
            setIsAnalyzing(false);
          },
        });
        clearSuggestedChanges();
        if (analyzedContract != null) {
          await setUpJumpToClauseTemplate(analyzedContract, setClauseResult);
          setAnalyzedContract(analyzedContract);
        }
        if (resetClauseViewOnClick) {
          navigateToClauseList();
        }
        onScanCompleted && onScanCompleted();
        setIsAnalyzing(false);
      }}
      className={getCSSClassForButtonSize(buttonSize)}
    >
      {isAnalyzing ? <Spinner size={SpinnerSize.medium} /> : <p className="action-button-text">Rescan</p>}
    </DefaultButton>
  );
}

function getCSSClassForButtonSize(size: RescanButtonSize): string {
  switch (size) {
    case RescanButtonSize.SMALL:
      return "rescan-button";
    case RescanButtonSize.STANDARD:
      return "";
  }
}
