import { Modal, Stack } from "@fluentui/react";
import React from "react";
import PincitesHeading, { PincitesHeadingType } from "../library/PincitesHeading";
import PincitesText, { PincitesTextType } from "../library/PincitesText";
import { CommentAdd24Regular } from "@fluentui/react-icons";
import PincitesCircleIcon from "../library/PincitesCircleIcon";
import { Margin } from "../library/Margin";
import PincitesDiffView from "../library/PincitesDiffView";
import PincitesButton, { PincitesButtonType } from "../library/PincitesButton";
import useClause from "../context/clause_context/useClause";
import PincitesLink from "../library/PincitesLink";
import { getClauseURL } from "../utils/urlBuilder";
import useAnalyzeContract from "../context/analyze_contract/useAnalyzeContract";
import { useAuthToken } from "../auth/useAuthToken";
import { createSuggestionForClause } from "../utils/apiUtils";
import useErrorHandling from "../error_handling/useErrorHandling";

export default function SuggestChangeModal({
  isShown,
  onCloseModal,
  originalText,
  newText,
  onCreateSuggestion,
}: {
  onCreateSuggestion: () => void;
  originalText: string;
  newText: string;
  isShown: boolean;
  onCloseModal: () => void;
}): React.JSX.Element {
  const { token, signIn } = useAuthToken();
  const { currentPlaybook } = useAnalyzeContract();
  const { selectedClause } = useClause();
  const { showErrorDialog } = useErrorHandling();
  if (!isShown) {
    return <></>;
  }
  const nonNullToken: string = token!;

  return (
    <Modal
      isOpen={isShown}
      onDismiss={onCloseModal}
      isBlocking={true}
      styles={{
        main: {
          height: "430px",
        },
      }}
    >
      <Stack horizontalAlign="center" styles={{ root: { maxWidth: "75%", margin: "0 auto" } }}>
        <PincitesCircleIcon Icon={CommentAdd24Regular} style={{ marginTop: 24 }} />
        <PincitesHeading headingType={PincitesHeadingType.H1} margin={[Margin.MARGIN_TOP_24]}>
          Suggest Changes
        </PincitesHeading>
        <Stack horizontalAlign="start" style={{ width: "100%" }}>
          <PincitesText margin={[Margin.MARGIN_TOP_16]}>Your edited guidance</PincitesText>
        </Stack>
        <PincitesDiffView oldText={originalText} newText={newText} />
        <PincitesText textType={PincitesTextType.SUB_TEXT} margin={[Margin.MARGIN_TOP_8]}>
          You and your team can review your suggested changes for{" "}
          <PincitesText textType={PincitesTextType.SUB_TEXT_BOLD}>{selectedClause?.name ?? ""}</PincitesText> in the{" "}
          <PincitesLink href={getClauseURL({ playbookID: currentPlaybook!.id, clauseID: selectedClause!.id })}>
            playbook web portal
          </PincitesLink>
          .
        </PincitesText>
        <Stack horizontal horizontalAlign="end" style={{ marginTop: 8, width: "100%" }}>
          <PincitesButton label="Close" onClick={onCloseModal} buttonType={PincitesButtonType.SECONDARY} />
          <PincitesButton
            label="Suggest"
            onClick={async () => {
              const suggestion = await createSuggestionForClause({
                clauseID: selectedClause!.id,
                signIn,
                token: nonNullToken,
                showErrorDialog,
                currentText: originalText,
                updatedText: newText,
              });
              if (suggestion != null) {
                onCreateSuggestion();
                onCloseModal();
              }
            }}
            buttonType={PincitesButtonType.PRIMARY}
          />
        </Stack>
      </Stack>
    </Modal>
  );
}
