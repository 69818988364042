import { Text } from "@fluentui/react";
import React from "react";
import { Margin, getCSStylesFromMargins } from "./Margin";

// Standard component for representing text in the Word App
export default function PincitesText({
  textType = PincitesTextType.STANDARD,
  children,
  margin = [],
  customStyle = {},
  onClick,
}: {
  customStyle?: React.CSSProperties;
  children: React.ReactNode;
  textType?: PincitesTextType;
  margin?: Margin[];
  onClick?: () => void;
}): React.JSX.Element {
  return (
    <Text
      onClick={onClick}
      style={{ ...customStyle, ...getCSStylesFromMargins(margin), ...getTextStyleFromTextType(textType) }}
    >
      {children}
    </Text>
  );
}

export enum PincitesTextType {
  STANDARD = "STANDARD",
  SUB_TEXT = "SUB_TEXT",
  SUB_TEXT_BOLD = "SUB_TEXT_BOLD",
  BOLD = "BOLD",
}

function getTextStyleFromTextType(textType: PincitesTextType): React.CSSProperties {
  switch (textType) {
    case PincitesTextType.STANDARD:
      return {};
    case PincitesTextType.SUB_TEXT:
      return {
        color: "var(--secondary-gray)",
        fontSize: "x-small",
      };
    case PincitesTextType.BOLD:
      return {
        fontWeight: "bold",
      };
    case PincitesTextType.SUB_TEXT_BOLD:
      return {
        color: "#6B7280",
        fontSize: "x-small",
        fontWeight: "bold",
      };
  }
}
