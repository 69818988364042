import { Text } from "@fluentui/react";
import React from "react";
import { Margin, getCSStylesFromMargins } from "./Margin";

export default function PincitesHeading({
  children,
  margin = [],
  customStyle = {},
  headingType,
}: {
  customStyle?: React.CSSProperties;
  children: React.ReactNode;
  headingType: PincitesHeadingType;
  margin?: Margin[];
}): React.JSX.Element {
  return (
    <Text style={{ ...customStyle, ...getCSStylesFromMargins(margin), ...getTextStyleFromHeadingType(headingType) }}>
      {children}
    </Text>
  );
}

export enum PincitesHeadingType {
  APP_TITLE = "APP_TITLE",
  H1 = "H1",
  H1_UNBOLDED = "H1_UNBOLDED",
  H2 = "H2",
}

function getTextStyleFromHeadingType(textType: PincitesHeadingType): React.CSSProperties {
  switch (textType) {
    case PincitesHeadingType.APP_TITLE:
      return {
        fontSize: 20,
        fontWeight: 800,
      };
    case PincitesHeadingType.H1:
      return { fontWeight: 700 };
    case PincitesHeadingType.H1_UNBOLDED:
      return { fontSize: "1.1rem" };
    case PincitesHeadingType.H2:
      return { fontWeight: 600 };
  }
}
