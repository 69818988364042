import { PrimaryButton } from "@fluentui/react";
import React from "react";

export enum PincitesButtonType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export default function PincitesButton({
  label,
  onClick,
  buttonType,
  Icon,
}: {
  label: string;
  onClick: () => void;
  buttonType: PincitesButtonType;
  Icon?: React.ComponentType<{ className?: string }>;
}): React.JSX.Element {
  return (
    <PrimaryButton
      text={label}
      onClick={onClick}
      style={getCSSFromPincitesButtonType(buttonType)}
      onRenderText={
        Icon
          ? (props, defaultRender) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Icon /> {defaultRender ? defaultRender(props) : null}
              </div>
            )
          : undefined
      }
    />
  );
}

function getCSSFromPincitesButtonType(buttonType: PincitesButtonType): React.CSSProperties {
  switch (buttonType) {
    case PincitesButtonType.PRIMARY:
      return {
        backgroundColor: "#0f6cbd",
        padding: "0 25px",
        overflow: "none",
        border: "#0f6cbd 1px solid",
        transition: "background-color 0.2s ease-in-out, color 0.2s ease-in-out",
      };
    case PincitesButtonType.SECONDARY:
      return {
        backgroundColor: "rgb(237, 236, 236)",
        color: "#333333",
        fontWeight: "bold",
        border: "lightgray 1px solid",
        transition: "background-color 0.2s ease-in-out, color 0.2s ease-in-out",
      };
  }
}
