import React from "react";
import { Breadcrumb, IBreadcrumbItem } from "@fluentui/react";
import useClause from "../context/clause_context/useClause";

function truncateString(text: string, length: number): string {
  if (text.length > length) {
    return text.substring(0, length) + "...";
  } else {
    return text;
  }
}

function ClauseBreadcrumb(): React.JSX.Element {
  const { selectedClause, navigateToClauseList } = useClause();
  const onBreadcrumbClick = (_: React.MouseEvent<HTMLElement> | undefined, item: IBreadcrumbItem | undefined): void => {
    if (item?.key === "Clauses") {
      navigateToClauseList();
    }
  };
  const items = [
    { text: "Clauses", key: "Clauses", onClick: onBreadcrumbClick },
    { text: truncateString(selectedClause!.name, 30), key: "Clause Name", isCurrentItem: true },
  ];
  return (
    <Breadcrumb
      items={items}
      maxDisplayedItems={2}
      styles={{
        root: {
          marginTop: "0px",
        },
        itemLink: {
          fontSize: "14px",
          color: "var(--brand-blue)",
        },
        item: {
          fontSize: "14px",
          fontWeight: "bold",
        },
      }}
    />
  );
}

export default ClauseBreadcrumb;
