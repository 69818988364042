export enum InclusionRequirement {
  REQUIRED = "required",
  OPTIONAL = "optional",
  PROHIBITED = "prohibited",
}

export function getDoesInclusionTypeSupportDiffing(inclusionType: InclusionRequirement): boolean {
  switch (inclusionType) {
    case InclusionRequirement.REQUIRED:
    case InclusionRequirement.OPTIONAL:
      return true;
    case InclusionRequirement.PROHIBITED:
      return false;
  }
}

export function getShouldInclusionTypeBeDisplayedWithClauseHeader(inclusionType: InclusionRequirement): boolean {
  switch (inclusionType) {
    case InclusionRequirement.REQUIRED:
    case InclusionRequirement.OPTIONAL:
      return true;
    case InclusionRequirement.PROHIBITED:
      return false;
  }
}

export function getLabelForInclusionRequirement(inclusionType: InclusionRequirement): string {
  switch (inclusionType) {
    case InclusionRequirement.REQUIRED:
      return "Required";
    case InclusionRequirement.OPTIONAL:
      return "Optional";
    case InclusionRequirement.PROHIBITED:
      return "Prohibited";
  }
}
