export enum Margin {
  MARGIN_TOP_1 = "MARGIN_TOP_1",
  MARGIN_TOP_2 = "MARGIN_TOP_2",
  MARGIN_TOP_4 = "MARGIN_TOP_4",
  MARGIN_TOP_6 = "MARGIN_TOP_6",
  MARGIN_TOP_8 = "MARGIN_TOP_8",
  MARGIN_TOP_16 = "MARGIN_TOP_16",
  MARGIN_TOP_24 = "MARGIN_TOP_24",

  MARGIN_BOTTOM_1 = "MARGIN_BOTTOM_1",
  MARGIN_BOTTOM_2 = "MARGIN_BOTTOM_2",
  MARGIN_BOTTOM_4 = "MARGIN_BOTTOM_4",
  MARGIN_BOTTOM_6 = "MARGIN_BOTTOM_6",
  MARGIN_BOTTOM_8 = "MARGIN_BOTTOM_8",
  MARGIN_BOTTOM_16 = "MARGIN_BOTTOM_16",
  MARGIN_BOTTOM_24 = "MARGIN_BOTTOM_24",

  MARGIN_LEFT_1 = "MARGIN_LEFT_1",
  MARGIN_LEFT_2 = "MARGIN_LEFT_2",
  MARGIN_LEFT_4 = "MARGIN_LEFT_4",
  MARGIN_LEFT_6 = "MARGIN_LEFT_6",
  MARGIN_LEFT_8 = "MARGIN_LEFT_8",
  MARGIN_LEFT_16 = "MARGIN_LEFT_16",
  MARGIN_LEFT_24 = "MARGIN_LEFT_24",

  MARGIN_RIGHT_1 = "MARGIN_RIGHT_1",
  MARGIN_RIGHT_2 = "MARGIN_RIGHT_2",
  MARGIN_RIGHT_4 = "MARGIN_RIGHT_4",
  MARGIN_RIGHT_6 = "MARGIN_RIGHT_6",
  MARGIN_RIGHT_8 = "MARGIN_RIGHT_8",
  MARGIN_RIGHT_16 = "MARGIN_RIGHT_16",
  MARGIN_RIGHT_24 = "MARGIN_RIGHT_24",

  MARGIN_HORZ_1 = "MARGIN_HORZ_1",
  MARGIN_HORZ_2 = "MARGIN_HORZ_",
}

export type MarginStyle = {
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
};

export function getCSStylesFromMargins(margins: Margin[]): MarginStyle {
  const styles: MarginStyle = {};

  margins.forEach((margin) => {
    const value = parseInt(margin.split("_").pop() ?? "");
    if (value === 0 || value == null) {
      return;
    }

    switch (true) {
      case margin.startsWith("MARGIN_TOP"):
        styles.marginTop = `${value}px`;
        break;
      case margin.startsWith("MARGIN_BOTTOM"):
        styles.marginBottom = `${value}px`;
        break;
      case margin.startsWith("MARGIN_LEFT"):
        styles.marginLeft = `${value}px`;
        break;
      case margin.startsWith("MARGIN_RIGHT"):
        styles.marginRight = `${value}px`;
        break;
      case margin.startsWith("MARGIN_HORZ"):
        styles.marginLeft = `${value}px`;
        styles.marginRight = `${value}px`;
        break;
    }
  });

  return styles;
}
