import React from "react";
import { Maybe, TSuggestedChange } from "../../types/ClauseTypes";

export type TSuggestedChangesContextProps = {
  getSuggestedChange: (clauseTemplateID: Maybe<string>) => Maybe<TSuggestedChange>;
  addSuggestedChange: (suggestedChange: TSuggestedChange) => void;
  clearSuggestedChanges: () => void;
};

const defaultSuggestedChangesContextValue: TSuggestedChangesContextProps = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSuggestedChange: (_clauseTemplateID) => null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addSuggestedChange: (_suggestedChange) => {},
  clearSuggestedChanges: () => {},
};

const SuggestedChangesContext = React.createContext(defaultSuggestedChangesContextValue);

export default SuggestedChangesContext;
