import React, { useState, PropsWithChildren } from "react";
import AnalyzeContractContext from "./AnalyzeContractContext";
import { Maybe, TAnalyzedContract, TPlaybook } from "../../types/ClauseTypes";

const AnalyzeContractProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [clauseIDToUserDefinedGuidance, setClauseIDToUserDefinedGuidance] = useState<Record<string, string>>({});
  const [analyzedCurrentContract, setCurrentAnalyzedContract] = useState<Maybe<TAnalyzedContract>>(null);
  const [currentPlaybook, setCurrentPlaybook] = React.useState<Maybe<TPlaybook>>(null);

  const clearAnalyzedContract = () => setCurrentAnalyzedContract(null);
  const setAnalyzedContract = (arg: TAnalyzedContract) => setCurrentAnalyzedContract(arg);
  const getUserDefinedGuidanceForClause = (clauseId: string) => clauseIDToUserDefinedGuidance[clauseId];
  const setUserDefinedGuidanceForClause = (clauseId: string, guidance: string) => {
    setClauseIDToUserDefinedGuidance({ ...clauseIDToUserDefinedGuidance, [clauseId]: guidance });
  };

  return (
    <AnalyzeContractContext.Provider
      value={{
        getUserDefinedGuidanceForClause,
        setUserDefinedGuidanceForClause,
        analyzedContract: analyzedCurrentContract,
        clearAnalyzedContract,
        setAnalyzedContract,
        currentPlaybook,
        setCurrentPlaybook,
      }}
    >
      {children}
    </AnalyzeContractContext.Provider>
  );
};

export default AnalyzeContractProvider;
