/* eslint-disable no-undef */
import { Maybe, TAnalyzedContract, TDiffEntry, TMatchedClause } from "../types/ClauseTypes";
import { TClauseTemplate } from "../types/ClauseTypes";
import { getHumanReadableDiff } from "./DiffUtils";
import { parseOoxml, parseOoxmlForSuggestedChanges } from "./ooxmlParser";

const MAX_SEARCH_LENGTH = 255;

let matchedClauseLocations: {
  location: Word.Range;
  matchedClause: TMatchedClause;
}[] = [];

let lastMatchedClauseTemplateID: Maybe<string> = null;

export function getDocumentFilename(): string {
  // Note: This call takes ~0ms. Office.js appears to cache the filename.
  return Office.context.document.url;
}

export async function getDocumentContent(): Promise<string> {
  let docContent = "";

  // eslint-disable-next-line no-undef
  await Word.run(async (context) => {
    // Get the OOXML (Open Office XML), which has all the document's details in it
    let ooxml = context.document.body.getOoxml();
    await context.sync();

    // Parse the OOXML to get the current text
    docContent = parseOoxml(ooxml.value).currentText;
  });

  return docContent;
}

export async function setUpJumpToClauseTemplate(
  analyzedContract: TAnalyzedContract,
  setClauseResult: (arg: { selectedClause?: Maybe<TClauseTemplate>; matchedClause?: Maybe<TMatchedClause> }) => void
): Promise<void> {
  lastMatchedClauseTemplateID = null;
  // Untrack any previously tracked locations (frees up memory)
  await untrackMatchedClauseLocations();

  // Find each matched clause and create a tracked location for it (for jump to clause)
  await trackMatchedClauseLocations(analyzedContract.matchedClauses);

  // Register a handler for jump to clause
  await registerJumpToClauseTemplateEventHandler(setClauseResult);
}

async function untrackMatchedClauseLocations() {
  for (let i = 0; i < matchedClauseLocations.length; i++) {
    matchedClauseLocations[i].location.untrack();
  }
  matchedClauseLocations = [];
}

export async function trackMatchedClauseLocations(matchedClauses: Array<TMatchedClause>) {
  await Word.run(async (context) => {
    // Get the visible text and the OOXML to build a search translator
    let visibleTextRange = context.document.body.load("text");
    let ooxml = context.document.body.getOoxml();
    await context.sync();

    // eslint-disable-next-line office-addins/load-object-before-read
    const parsedOoxml = parseOoxml(ooxml.value);
    const visibleText = cleanText(visibleTextRange.text);

    for (let i = 0; i < matchedClauses.length; i++) {
      const matchedClause = matchedClauses[i];
      var textToFind = matchedClause.matchedDocumentText;
      const textRange = getFirstRangeForText(textToFind, context.document.body.getRange(), visibleText, parsedOoxml);

      // Store the range and the matched clause for future use
      matchedClauseLocations.push({ location: textRange.track(), matchedClause: matchedClause });
    }

    try {
      await context.sync();
    } catch (error) {
      if (error instanceof Error) {
        console.error("Unexpected error while trying to track matched clause locations: " + error);
        if (typeof error === "object" && error !== null && "debugInfo" in error) {
          console.log(error.debugInfo);
        }
      }
      throw error;
    }
  });
}

async function registerJumpToClauseTemplateEventHandler(
  setClauseResult: (arg: { selectedClause?: Maybe<TClauseTemplate>; matchedClause?: Maybe<TMatchedClause> }) => void
) {
  Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, async () => {
    let locations: Word.Range[] = [];
    for (let i = 0; i < matchedClauseLocations.length; i++) {
      locations.push(matchedClauseLocations[i].location);
    }
    if (locations.length === 0) {
      // If there are no matched clauses, bail out.
      // (If we don't we'll get an error when Word tries to find an object with a context in the array.)
      return;
    }

    Word.run(locations, async (context) => {
      // Get the current selected text
      const selection = context.document.getSelection().load("isEmpty");
      selection.getComments();

      // Compare the current selection with the location of the matched clauses
      let comparisons = [];
      for (let i = 0; i < locations.length; i++) {
        comparisons.push(locations[i].compareLocationWith(selection));
      }

      // Load the comparisons
      try {
        await context.sync();
      } catch (error) {
        if (error instanceof Error && "errorLocation" in error && error.errorLocation === "Range.getComments") {
          // Do nothing. This means that the current selection is in a comment
          // and we shouldn't update the selected clause.
          //
          // (If there's a better way to detect this than fetching comments and seeing
          // if they error then we should do that instead! Grey couldn't find one)
          return;
        }
        console.error("Unexpected error while getting selection: " + error);
        if (typeof error === "object" && error !== null && "debugInfo" in error) {
          console.log(error.debugInfo);
        }
        throw error;
      }

      if (!selection.isEmpty) {
        // If the selection is not empty bail out. We only jump to clause on click
        return;
      }

      // Otherwise find the first matched clause that contains the current selection
      for (let i = 0; i < comparisons.length; i++) {
        if (comparisons[i].value === "Contains") {
          const clauseTemplateID = matchedClauseLocations[i].matchedClause.clauseTemplate.id;
          // If the paragraph is a matched clause, jump to the clause template
          setClauseResult({
            selectedClause: matchedClauseLocations[i].matchedClause.clauseTemplate,
            matchedClause: matchedClauseLocations[i].matchedClause,
          });
          if (lastMatchedClauseTemplateID !== clauseTemplateID) {
            scrollToTopOfAddIn();
            lastMatchedClauseTemplateID = clauseTemplateID;
          }
          return;
        }
      }

      // If we didn't find a matched clause, clear the selected clause
      setClauseResult({ selectedClause: null });
      lastMatchedClauseTemplateID = null;
    });
  });
}

export function scrollToTopOfAddIn() {
  const addInContainer = document.querySelector("#clause-list-container");
  if (addInContainer) {
    addInContainer.scrollTop = 0;
  }
}

// Responsible for reporting back on the status of the original
// matched clause in the document
export async function getTextStatusForMatchedClause(
  matchedClause: TMatchedClause
): Promise<{ textModified: boolean; redlinesExistInText: boolean }> {
  // Get the clause's location
  let matchedClauseRange = getMatchedClauseLocation(matchedClause);

  // Check the text within the range to see if it has changed or has redlines
  let textModified = false;
  let redlinesExistInText = false;
  await Word.run(matchedClauseRange, async function (context) {
    // Get the text as though all tracked changes have been accepted.
    let ooxml = matchedClauseRange.getOoxml();
    await context.sync();

    // eslint-disable-next-line office-addins/load-object-before-read
    const parsedOoxml = parseOoxml(ooxml.value);
    const currentTextInRange = parsedOoxml.currentText;

    const textFromMatchedClause = matchedClause.matchedDocumentText;

    // Compare the current text to the text from the matched clause (detected previously)
    textModified = currentTextInRange.trim() !== textFromMatchedClause.trim();
    redlinesExistInText = parseOoxmlForSuggestedChanges(ooxml.value);
  });
  return { textModified, redlinesExistInText };
}

export async function redlineAndCommentOnMatchedClause(
  matchedClause: TMatchedClause,
  textToRedlineWith: string,
  suggestedTextExplanation: string
): Promise<void> {
  try {
    let matchedClauseRange = getMatchedClauseLocation(matchedClause);

    // Redline the clause in Word
    await Word.run(matchedClauseRange, async function (context) {
      // Load some properties from the document we need:
      // - the change tracking mode (so we know the initial state, and can switch back to it later)
      // - the text currently being displayed in the document (so we know what display preferences the user has)
      // - the clause's ooxml (so we can get the current reviewed text, and build a clause-specific search translator)
      // - the clause's comments (so we can check if the suggested text explanation already exists)
      context.document.load("changeTrackingMode");
      matchedClauseRange.load("text");
      let ooxml = matchedClauseRange.getOoxml();
      const comments = matchedClauseRange.getComments();
      comments.load("items/content");

      await context.sync();

      // Redline the clause
      redlineLocationWithText(
        context.document,
        matchedClauseRange,
        textToRedlineWith,
        cleanText(matchedClauseRange.text),
        ooxml.value // eslint-disable-line office-addins/load-object-before-read
      );

      // Add a comment to the clause if necessary
      if (suggestedTextExplanation.length > 0) {
        commentExplanation(matchedClauseRange, comments.items, suggestedTextExplanation);
      }

      // Save the changes
      await context.sync();
    });
  } catch (error) {
    if (error instanceof Error) {
      console.error("Unexpected error while trying to redline the document: " + error);
      if (typeof error === "object" && error !== null && "debugInfo" in error) {
        console.log(error.debugInfo);
      }
    }
    throw error;
  }
}

function commentExplanation(
  matchedClauseRange: Word.Range,
  existingComments: Word.Comment[],
  suggestedTextExplanation: string
): void {
  // Check if a comment with the desired content already exists
  for (let comment of existingComments) {
    if (comment.content === suggestedTextExplanation) {
      return;
    }
  }

  matchedClauseRange.insertComment(suggestedTextExplanation);
}

function redlineLocationWithText(
  document: Word.Document,
  matchedClauseRange: Word.Range,
  textToRedlineWith: string,
  visibleText: string,
  ooxml: string
): void {
  // Redline the clause in Word
  //
  // Get the clause's current (reviewed) text
  // eslint-disable-next-line office-addins/load-object-before-read
  const parsedOoxml = parseOoxml(ooxml);
  const currentTextInRange = parsedOoxml.currentText;

  // Enable track changes if they're not enabled already. (We only do this if
  // necessary because some docs don't allow the track changes setting to be changed)
  const initialTrackingMode = document.changeTrackingMode;
  if (initialTrackingMode === Word.ChangeTrackingMode.off) {
    document.changeTrackingMode = Word.ChangeTrackingMode.trackMineOnly;
  }

  // Get the diff between our suggested text and the current text. The base for the diff
  // is the current text in the document as if all tracked changes have been accepted
  const groupedDiffList = getHumanReadableDiff(currentTextInRange, textToRedlineWith);

  // Build a clause-specific translator to convert the clause's current text into text we need
  // to pass to Word's `search(...)` function. We can't use the global translator because
  // we're searching for very short strings which may occur higher up in the doc.
  // Note: this is a no-op unless the text displayed on the user's screen differs from the
  // current text in the clause as if all tracked changes have been accepted.
  var translateForInClauseSearch = function (text: string, startPosition?: number | undefined) {
    if (visibleText.trim() !== currentTextInRange.trim()) {
      return parsedOoxml.searchTranslator(text, startPosition);
    } else {
      return text;
    }
  };

  // Put it all together and redline the clause
  modifyRangeWithDiffList(matchedClauseRange, groupedDiffList, translateForInClauseSearch);

  // Reset the change tracking mode to what it was before (if required)
  if (initialTrackingMode !== document.changeTrackingMode) {
    document.changeTrackingMode = initialTrackingMode;
  }
}

/*
  Redlining Logic:
  The code below will take in the diffs and redline the Word document accordingly. The
  redlining logic works at the clause level. Word only works in ranges and does not consider
  indices so the approach is as follows:
  1. Get the whole clause range (`expandedRange`)
  2. Keep track of the last range that was processed by the redlining logic (`lastRangeProcessed`)
  3. We have a search function which will search the remainder of expandedRange based on where lastRangeProcessed ends
  4. On there being no removal nor addition in the diff (i.e. a "keep" for the diff), call the search function and get the text range. Set this to be lastRangeProcessed
  5. On an addition, insert the text at the end of lastRangeProcessed and set this to be lastRangeProcessed
  6. On a removal, call the search function and get the text range. Delete this range and set this to be lastRangeProcessed
*/
function modifyRangeWithDiffList(
  expandedRange: Word.Range,
  groupedDiffList: Array<TDiffEntry>,
  translateForInClauseSearch: (text: string, startPosition?: number | undefined) => string
) {
  let lastRangeProcessed: Maybe<Word.Range> = null;
  let positionInReviewedClause = 0;
  for (let i = 0; i < groupedDiffList.length; i++) {
    let entry = groupedDiffList[i];

    // Translate the text to search for.
    // Note: It's important we provide our position in the string (and advance it as we go)
    // because the strings we're searching for may be very short (single words).
    let textToSearchFor = translateForInClauseSearch(entry.value, positionInReviewedClause);
    if (entry.added == null) {
      positionInReviewedClause += entry.value.length;
    }

    if (entry.added == null && entry.removed == null) {
      // search for the text and set lastRangeProcessed equal to the end of the search range
      const range: Maybe<Word.Range> = findSpecificRange(textToSearchFor, expandedRange, lastRangeProcessed);
      if (range != null) {
        lastRangeProcessed = range.getRange("End");
      }
    }

    if (entry.added) {
      // Get the range to insert into
      const rangeToInsertInto: Word.Range = lastRangeProcessed == null ? expandedRange : lastRangeProcessed;
      // insert into the range and set the last processed pointer to the text we just inserted
      const insertedRange = rangeToInsertInto.insertText(entry.value, "Start");
      lastRangeProcessed = insertedRange.getRange("End");
    } else if (entry.removed) {
      // find and delete the text and set to pointer to be the end of that text
      const rangeToDelete: Maybe<Word.Range> = findSpecificRange(textToSearchFor, expandedRange, lastRangeProcessed);
      if (rangeToDelete != null) {
        rangeToDelete.delete();
        lastRangeProcessed = rangeToDelete.getRange("End");
      }
    }
  }
}

// Function is responsible for getting the range object of a given text
// in the window between lastRangeProcessed.START and expandedRange.END
// Word has a limit of 255 chars to search so this will automatically
// split up the search into 255 char chunks
function findSpecificRange(
  textToFind: string,
  expandedRange: Word.Range,
  lastRangeProcessed: Maybe<Word.Range>
): Maybe<Word.Range> {
  let rangeToSearch = null;
  let searchResults = null;
  let startRange: Maybe<Word.Range> = null;
  let endRange: Maybe<Word.Range> = null;

  if (lastRangeProcessed == null) {
    rangeToSearch = expandedRange;
  } else {
    // Create a new range that starts from the end of lastRange to the rest of the clause
    rangeToSearch = lastRangeProcessed.getRange("End").expandTo(expandedRange.getRange("End"));
  }

  // Search for the first textToFind within the rangeToSearch
  // since we move left to right in the document
  // Because of the microsoft limit of 255 chars, we search 255
  // chars at a time
  for (let i = 0; i < textToFind.length; i += MAX_SEARCH_LENGTH) {
    const substringToSearch = textToFind.substring(i, i + MAX_SEARCH_LENGTH);
    searchResults = rangeToSearch.search(substringToSearch);

    // Why only consider the first search result?
    // What if there are multiple search results?
    // Two reasons to only consider first search result:
    // 1. The diff tool reads left to right
    // 2. Say there is a case where the first MAX_SEARCH_LENGTH
    // chars result in two search cases. The odds in the
    // english language of two strings with MAX_SEARCH_LENGTH
    // is very small
    const firstSearchResult: Word.Range = searchResults.getFirstOrNullObject();

    // Set start range at the first match
    // This will be the "starting pointer" of the very
    // long range we return
    if (i === 0) {
      startRange = firstSearchResult.getRange("Start");
    }

    // Move the end range to the end of this match. By the thime we
    // finish the loop, the end range will be the end of the last match
    endRange = firstSearchResult.getRange("End");

    // Adjust the rangeToSearch to start from the end of the match
    // as we already processed the last MAX_SEARCH_LENGTH chars
    // in the search
    rangeToSearch = endRange.expandTo(expandedRange.getRange("End"));
  }

  // return a new range that spans from the start of the first match to the end of the last match
  if (startRange == null || endRange == null) {
    return null;
  }
  return startRange.expandToOrNullObject(endRange);
}

export async function scrollToClauseInDocument(matchedClause: TMatchedClause): Promise<void> {
  let matchedClauseRange = getMatchedClauseLocation(matchedClause);

  await Word.run(matchedClauseRange, async function () {
    matchedClauseRange.select(Word.SelectionMode.select);
  });
}

function cleanText(docContent: string): string {
  docContent = removeCommentMarkers(docContent);
  docContent = replaceNonBreakingHyphens(docContent);
  return docContent;
}

// Remove the `u0005` that Word adds to mark comment anchors.
function removeCommentMarkers(docContent: string): string {
  while (docContent.includes("\u0005")) {
    docContent = docContent.replace("\u0005", "");
  }
  return docContent;
}

// Replace the `u001E` that Word users for a non-breaking hyphen. A simple
// hyphen will still be found by Word's `search(...)` function, and is
// understood by other systems.
function replaceNonBreakingHyphens(docContent: string): string {
  while (docContent.includes("\u001E")) {
    docContent = docContent.replace("\u001E", "-");
  }
  return docContent;
}

function getMatchedClauseLocation(matchedClause: TMatchedClause): Word.Range {
  // Get the clause's location
  let matchedClauseLocation = matchedClauseLocations.find((location) => {
    return location.matchedClause.clauseTemplate.id === matchedClause.clauseTemplate.id;
  });
  if (matchedClauseLocation == null) {
    // This should never happen
    throw new Error("Could not find the clause in the document");
  }
  return matchedClauseLocation.location;
}

function getFirstRangeForText(
  textToFind: string,
  rangeToSearch: Word.Range,
  visibleText: string,
  parsedOoxml: {
    searchTranslator: (textToTranslate: string, startPosition?: number | undefined) => string;
    currentText: string;
  }
): Word.Range {
  // Search for the text's start and end separately and then combine the results.
  //
  // NOTE: For this to work, we need to be able to find both strings - if we can't
  // then calling `expandToOrNullObject` at the end of this function will throw an error.
  // To guard against that we check that the strings we're about to search for are in the
  // document's visible text (which is what Word searches).
  const startOfText = textToFind.substring(0, MAX_SEARCH_LENGTH);
  const endOfText = textToFind.substring(Math.max(textToFind.length - MAX_SEARCH_LENGTH, 0), textToFind.length);

  // PART ONE: Search for the start of the text. Translate it if necessary.
  var startOfTextToFind = startOfText;
  if (!visibleText.includes(startOfText)) {
    // If the text isn't visible, we need to search for the translated text instead
    startOfTextToFind = parsedOoxml.searchTranslator(startOfText).substring(0, MAX_SEARCH_LENGTH);

    if (!visibleText.includes(startOfTextToFind)) {
      // If the translated text also isn't visible then something has gone wrong. Fall back to searching
      // for just the beginning of the text, and returning a null object if we can't find it.
      // (We should be debugging any time we get here. It means something is wrong with the translation.)
      const startOfTextRange = rangeToSearch.search(startOfTextToFind).getFirstOrNullObject();
      return startOfTextRange.expandTo(startOfTextRange.paragraphs.getLast().getRange("End"));
    }
  }
  const startOfTextRange = rangeToSearch.search(startOfTextToFind).getFirstOrNullObject();

  // PART TWO: Check if a search for the second part of the text is necessary. If the `startOfTextToFind`
  // we ended up searching for is less than the MAX_SEARCH_LENGTH, then we're done.
  if (startOfTextToFind.length < MAX_SEARCH_LENGTH) {
    return startOfTextRange;
  }

  // PART THREE: Search for the end of the text. Translate it if necessary. Only search in the range
  // after the start text was found.
  var endOfTextToFind = endOfText;
  visibleText = visibleText.substring(visibleText.indexOf(startOfTextToFind) + startOfTextToFind.length);
  if (!visibleText.includes(endOfText)) {
    const translatedText = parsedOoxml.searchTranslator(endOfText);
    endOfTextToFind = translatedText.substring(
      Math.max(translatedText.length - MAX_SEARCH_LENGTH, 0),
      translatedText.length
    );

    if (!visibleText.includes(endOfTextToFind)) {
      // As above, if we get here, something has gone wrong.
      return startOfTextRange.expandTo(startOfTextRange.paragraphs.getLast().getRange("End"));
    }
  }
  const endOfTextRangeToSearch = startOfTextRange.expandToOrNullObject(rangeToSearch.getRange("End"));
  const endOfTextRange = endOfTextRangeToSearch.search(endOfTextToFind).getFirstOrNullObject();

  // PART FOUR: Conbine the two ranges (neither of which should be null objects)
  return startOfTextRange.expandToOrNullObject(endOfTextRange);
}
