import React from "react";
import { TSignIn } from "../auth/AuthContext";
import useFeedbackModal from "../feedback/useFeedbackModal";
import { IIconProps, IconButton, TooltipHost } from "@fluentui/react";

export default function PincitesThumbFeedbackButton({
  likeOrDislike,
  token,
  suggestedText,
  signIn,
  subject,
}: {
  subject: string;
  likeOrDislike: "Like" | "Dislike";
  token: string;
  signIn: TSignIn;
  suggestedText: string;
}): React.JSX.Element {
  const { showFeedbackModal } = useFeedbackModal();
  const thumbsUpIcon: IIconProps = {
    iconName: likeOrDislike,
    className: "feedback-icon",
  };

  const initialDetails = `I ${
    likeOrDislike === "Like"
      ? `find the following ${subject} to be helpful: `
      : `do not find the following ${subject} to be helpful: `
  } ${"\n\n"}${suggestedText.replace("###", "")} ${"\n\n"}Additional context to help us improve: ${"\n"}`;

  return (
    <TooltipHost content={`${likeOrDislike === "Like" ? "Good" : "Bad"} suggestion`}>
      <IconButton
        iconProps={thumbsUpIcon}
        onClick={() => {
          showFeedbackModal({
            signIn,
            token,
            shouldShow: true,
            initialDescription: `Feedback on ${subject}`,
            initialDetails,
            payload: {
              "Feedback on": subject,
              "Like or dislike": likeOrDislike,
              "Suggested text": suggestedText,
            },
          });
        }}
        className="feedback-icon-button"
        styles={{ root: { marginBottom: "-10px" } }}
      />
    </TooltipHost>
  );
}
