import React from "react";

export default function PincitesCircleIcon({
  Icon,
  style,
  backgroundColor = "#F1F8FF",
  iconColor = "#0972D3",
  size = 48,
}: {
  size?: number;
  iconColor?: string;
  backgroundColor?: string;
  Icon: React.ComponentType<{ className?: string; onClick: () => void; style?: React.CSSProperties }>;
  style?: React.CSSProperties;
}): React.JSX.Element {
  return (
    <div
      style={{
        ...style,
        borderRadius: 24,
        backgroundColor,
        color: iconColor,
        width: size,
        height: size,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Icon onClick={() => {}} />
    </div>
  );
}
