import React from "react";
import { AuthContext } from "./AuthContext";

export const useAuthToken = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuthToken must be used within a AuthProvider");
  }

  return context;
};
