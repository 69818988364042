import axios, { Method } from "axios";
import { Maybe, TAnalyzedContract, TClauseTemplate, TPlaybook, TSuggestedChange } from "../types/ClauseTypes";
import { PreferenceLevel } from "../types/PreferenceLevel";
import { TShowErrorDialog } from "../error_handling/ErrorHandlingContext";
import { InclusionRequirement } from "../types/InclusionRequirement";
import { TSignIn } from "../auth/AuthContext";
import { getIsTokenExpired } from "../auth/AuthUtils";
import { TGuidanceSuggestion } from "../types/SuggestionTypes";
import {
  encodeSuggestedChangeSteps,
  getCurrentBaseVersion,
  getProseMirrorSchema,
  getSuggestionStepsFromTexts,
} from "./SuggestedChangesUtil";
import { ReplaceStep } from "prosemirror-transform";

const API_DEFAULT_TIMEOUT_IN_MS = 90000;

export async function analyzeDocument({
  body,
  filename,
  playbookID,
  token,
  showErrorDialog,
  onError,
  signIn,
}: {
  signIn: TSignIn;
  body: string;
  filename: string;
  playbookID: string;
  token: string;
  showErrorDialog: TShowErrorDialog;
  onError: (error: Error) => void;
}): Promise<Maybe<TAnalyzedContract>> {
  try {
    const apiRoute = `/playbooks/${playbookID}/analyze_document`;
    const apiBody = {
      contract_text: body,
      contract_filename: filename,
    };
    const response = await runRESTRequest({ signIn, token, apiRoute, method: "POST", body: apiBody });
    // check response code for non 2xx
    if (response.status.toString().startsWith("4") || response.status.toString().startsWith("5")) {
      throw new Error(`An error occured while analyzing the document`);
    }
    return parseAnalyzeDocumentResponse(response.data);
  } catch (error) {
    let errorMsg = "We couldn't analyze your documnet. Please try again while we work on a fix.";
    if (axios.isAxiosError(error) && error.response) {
      if (typeof error.response.data === "string" && error.response.data.includes("The network connection was lost")) {
        errorMsg = "Connection interrupted. Please try again";
      }
    }
    if (error instanceof Error) {
      handleError(error, token, showErrorDialog, errorMsg);
      onError(error);
    }
    return null;
  }
}

export async function suggestClauseChanges({
  documentClauseText,
  clauseTemplateID,
  token,
  guidanceText,
  onError,
  showErrorDialog,
  signIn,
}: {
  signIn: TSignIn;
  documentClauseText: string;
  clauseTemplateID: string;
  guidanceText: string;
  token: string;
  showErrorDialog: TShowErrorDialog;
  onError: (error: Error) => void;
}): Promise<Maybe<TSuggestedChange>> {
  try {
    const body = {
      current_clause_text: documentClauseText,
      guidance: guidanceText,
      clause_template_id: clauseTemplateID,
    };
    const response = await runRESTRequest({ signIn, token, apiRoute: "/suggested_changes", method: "POST", body });
    // check response code for non 2xx
    if (response.status.toString().startsWith("4") || response.status.toString().startsWith("5")) {
      throw new Error(`An error occured while fetching suggestions`);
    }
    return parseSuggestedClauseChangesResponse(response.data, clauseTemplateID);
  } catch (error) {
    if (error instanceof Error) {
      const errorMsg = "We couldn't fetch suggestions for this clause. Please try again while we work on a fix.";
      handleError(error, token, showErrorDialog, errorMsg);
      onError(error);
    }
    return null;
  }
}

export async function createSuggestionForClause({
  token,
  clauseID,
  signIn,
  showErrorDialog,
  updatedText,
  currentText,
}: {
  currentText: string;
  updatedText: string;
  signIn: TSignIn;
  showErrorDialog: TShowErrorDialog;
  token: string;
  clauseID: string;
}): Promise<Maybe<TGuidanceSuggestion>> {
  try {
    const steps = getSuggestionStepsFromTexts(currentText, updatedText);
    const allSuggestions = await getGuidanceSuggestionsForClause({ token, clauseID, signIn, showErrorDialog });
    const newBaseVersion = getCurrentBaseVersion(allSuggestions);
    const response = await runRESTRequest({
      signIn,
      token,
      apiRoute: `/clause_template_guidance_changes`,
      method: "POST",
      body: {
        changes: encodeSuggestedChangeSteps(steps),
        state: "pending",
        updated_text: updatedText,
        clause_template_id: clauseID,
        base_version: newBaseVersion,
      },
    });
    // check response code for non 2xx
    if (response.status.toString().startsWith("4") || response.status.toString().startsWith("5")) {
      throw new Error(`An error occured while creating suggestions for the clause`);
    }
    return response;
  } catch (error) {
    if (error instanceof Error) {
      const errorMsg = "We couldn't create the suggestion for the clause. Please try again while we work on a fix.";
      handleError(error, token, showErrorDialog, errorMsg);
    }
    return null;
  }
}

async function getGuidanceSuggestionsForClause({
  token,
  clauseID,
  signIn,
  showErrorDialog,
}: {
  signIn: TSignIn;
  showErrorDialog: TShowErrorDialog;
  token: string;
  clauseID: string;
}): Promise<TGuidanceSuggestion[]> {
  try {
    const response = await runRESTRequest({
      signIn,
      token,
      apiRoute: `/clause_template_guidance_changes?clause_template_id=${clauseID}`,
      method: "GET",
    });
    // check response code for non 2xx
    if (response.status.toString().startsWith("4") || response.status.toString().startsWith("5")) {
      throw new Error(`An error occured while fetching suggestions for the clause`);
    }
    return parseGetClauseSuggestionsResponse(response.data);
  } catch (error) {
    if (error instanceof Error) {
      const errorMsg = "We couldn't fetch existing suggestions for the clause";
      handleError(error, token, showErrorDialog, errorMsg);
    }
    return [];
  }
}

export async function markSuggestedChangeApplied({
  suggestedChange,
  token,
  showErrorDialog,
  signIn,
}: {
  signIn: TSignIn;
  suggestedChange: TSuggestedChange;
  token: string;
  showErrorDialog: TShowErrorDialog;
}): Promise<void> {
  try {
    const apiRoute = `/suggested_changes/${suggestedChange.suggestedChangeID}/mark_as_applied`;
    const response = await await runRESTRequest({ signIn, token, apiRoute, method: "POST", body: {} });
    // check response code for non 2xx
    if (response.status.toString().startsWith("4") || response.status.toString().startsWith("5")) {
      throw new Error(`An error occured while applying the suggestion`);
    }
    if (
      "data" in response &&
      typeof response.data === "object" &&
      response.data !== null &&
      "errors" in response.data
    ) {
      throw new Error("Error occured while applying the suggestion: " + response.data.errors.join(", "));
    }
  } catch (error) {
    // Specific logic to this request - a 409 throws if the suggestion has already been applied
    // We should just swallow this
    if (axios.isAxiosError(error) && error.response && error.response.status === 409) {
      if (typeof error.response.data === "string" && error.response.data.includes("already marked as applied")) {
        return;
      }
    }
    if (error instanceof Error) {
      const errorMsg = "We couldn't apply this suggestion. Please try again while we work on a fix.";
      handleError(error, token, showErrorDialog, errorMsg);
    }
  }
}

export async function submitFeedback({
  feedbackSummary,
  feedbackDetails,
  token,
  showErrorDialog,
  signIn,
}: {
  signIn: TSignIn;
  feedbackSummary: string;
  feedbackDetails: string;
  token: string;
  showErrorDialog: TShowErrorDialog;
}): Promise<void> {
  try {
    const body = {
      subject: feedbackSummary,
      message: feedbackDetails,
    };
    await await runRESTRequest({ signIn, token, apiRoute: "/feedback", method: "POST", body });
  } catch (error) {
    if (error instanceof Error) {
      const errorMsg = "We couldn't submit your feedback. Please try again or reach out to us at hello@pincites.com.";
      handleError(error, token, showErrorDialog, errorMsg);
    }
  }
}

export async function createOrUpdateUser({
  token,
  showErrorDialog,
}: {
  token: string;
  showErrorDialog: TShowErrorDialog;
}) {
  const body = {
    id_token: token,
  };

  try {
    const response = await runRESTRequest({ apiRoute: "/users", method: "POST", body });
    if (response.status.toString().startsWith("4") || response.status.toString().startsWith("5")) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    if (error instanceof Error) {
      const errorMsg = "We couldn't validate your account. Please try again.";
      handleError(error, token, showErrorDialog, errorMsg);
    }
  }
}

export async function getPlaybooks({
  token,
  showErrorDialog,
  signIn,
}: {
  signIn: TSignIn;
  token: string;
  showErrorDialog: TShowErrorDialog;
}): Promise<TPlaybook[]> {
  try {
    const response = await runRESTRequest({ signIn, token, apiRoute: "/playbooks", method: "GET" });
    return response.data.map((playbook: any) => {
      return {
        id: playbook.id,
        name: playbook.name,
        description: playbook.description,
        comment: playbook.comment,
      };
    });
  } catch (error) {
    if (error instanceof Error) {
      const errorMsg = "We couldn't fetch your playbooks. Please reload the app and try again.";
      handleError(error, token, showErrorDialog, errorMsg);
    }
    throw error;
  }
}

async function runRESTRequest({
  token,
  apiRoute,
  method,
  body,
  signIn,
}: {
  signIn?: TSignIn;
  token?: string;
  apiRoute: string;
  method: Method;
  body?: Record<string, any>;
}): Promise<any> {
  let tokenToUse = token;
  const url = `${getBaseAPIUrl()}/api${apiRoute}`;
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  if (token != null) {
    if (getIsTokenExpired(token)) {
      if (signIn != null) {
        tokenToUse = await signIn();
      }
    }
    // check if the token is expired
    headers["Authorization"] = `Bearer ${tokenToUse}`;
  }
  if (method === "GET") {
    return await axios.get(url, {
      headers,
      timeout: API_DEFAULT_TIMEOUT_IN_MS,
    });
  } else if (method === "POST") {
    return await axios.post(url, body, {
      headers,
      timeout: API_DEFAULT_TIMEOUT_IN_MS,
    });
  }
}

function handleError(error: Error, token: string, showErrorDialog: TShowErrorDialog, errorMsg?: string): void {
  if (axios.isAxiosError(error) && error.response) {
    const statusCode = error.response.status;
    const errorResponseBody = error.response.data;
    if (statusCode === 401) {
      if (errorResponseBody.toString().includes("organization")) {
        const errMsg = "Your organization has not signed up for Pincites. Visit app.pincites.com/signup to sign up.";
        showErrorDialog({ errorMsg: errMsg, error, token });
      }
    } else if (statusCode === 422) {
      const errMsg = "We couldn't process your request:" + errorResponseBody.toString();
      showErrorDialog({ errorMsg: errMsg, error, token });
      return;
    }
  }
  const standardErrorMsg = "Something went wrong. Our team is working on a fix.";
  showErrorDialog({ errorMsg: errorMsg ?? standardErrorMsg, error, token });
}

function getBaseAPIUrl(): string {
  return getProdAPIUrl();
}

function getProdAPIUrl(): string {
  return "https://api.pincites.com";
}

function parseAnalyzeDocumentResponse(apiResponse: any): TAnalyzedContract {
  const analyzedContract: TAnalyzedContract = {
    matchedClauses: apiResponse.matched_clauses.map((matchedClause: any) => ({
      clauseTemplate: parseClauseTemplateFromAnalyzeDocumentResponse(matchedClause),
      matchedDocumentText: matchedClause.matched_document_text,
      isStandardText: matchedClause.is_standard_text,
      bestMatchIsWithStandardText: matchedClause.best_match_is_with_standard_text,
      bestMatchTextAlternativeId: matchedClause.best_match_text_alternative_id,
      matchScore: matchedClause.match_score,
    })),
    missingClauses: apiResponse.missing_clauses.map((missingClause: any) => ({
      clauseTemplate: parseClauseTemplateFromAnalyzeDocumentResponse(missingClause),
    })),
  };

  return analyzedContract;
}

function parseGetClauseSuggestionsResponse(apiResponse: any): TGuidanceSuggestion[] {
  return apiResponse.map((suggestion: any) => ({
    createdBy: {
      name: suggestion.created_by.name,
    },
    createdAt: suggestion.created_at,
    status: suggestion.state,
    baseVersion: suggestion.base_version,
    steps: JSON.parse(suggestion.changes).map((step: any) => {
      return deserializeSuggestedChangeStep(step);
    }),
    id: suggestion.id,
  }));
}

function deserializeSuggestedChangeStep(json: string): ReplaceStep {
  const schema = getProseMirrorSchema();
  return ReplaceStep.fromJSON(schema, json) as ReplaceStep;
}

function parseClauseTemplateFromAnalyzeDocumentResponse(clause: any): TClauseTemplate {
  return {
    id: clause.clause_template.id,
    playbookId: clause.clause_template.playbook_id,
    name: clause.clause_template.name,
    text: clause.clause_template.text,
    guidance: clause.clause_template.guidance,
    notes: clause.clause_template.notes,
    inclusionRequirement: clause.clause_template.inclusion_requirement as InclusionRequirement,
    clauseTextAlternatives: clause.clause_template.clause_text_alternatives.map((clauseTextAlternative: any) => ({
      id: clauseTextAlternative.id,
      clauseTemplateId: clauseTextAlternative.clause_template_id,
      text: clauseTextAlternative.text,
      preferenceLevel: clauseTextAlternative.preference_level as PreferenceLevel,
      comment: clauseTextAlternative.comment,
    })),
  };
}

function parseSuggestedClauseChangesResponse(apiResponse: any, clauseTemplateId: string): TSuggestedChange {
  return {
    suggestedText: apiResponse.suggested_text,
    clauseTemplateId: clauseTemplateId,
    suggestedChangeID: apiResponse.id,
    explanation: apiResponse.explanation,
  };
}
