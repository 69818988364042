import React from "react";
import { Maybe, TClauseTemplate, TMatchedClause } from "../../types/ClauseTypes";

export type ClauseResult = {
  selectedClause: Maybe<TClauseTemplate>;
  matchedClause: Maybe<TMatchedClause>;
};

export type TClauseContextProps = {
  selectedClause: Maybe<TClauseTemplate>;
  matchedClause: Maybe<TMatchedClause>;
  navigateToClauseList: () => void;
  setClauseResult: (arg: { selectedClause?: Maybe<TClauseTemplate>; matchedClause?: Maybe<TMatchedClause> }) => void;
};

const defaultClauseContextValue: TClauseContextProps = {
  selectedClause: null,
  matchedClause: null,
  navigateToClauseList: () => {},
  // TODO(mike): Delete these soon
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setClauseResult: (_arg) => {},
};

const ClauseContext = React.createContext(defaultClauseContextValue);

export default ClauseContext;
