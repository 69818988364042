import React, { useEffect, useRef, useState } from "react";

function PincitesExpandableText({ text }: { text: string }): React.JSX.Element {
  const maxLines = 3;
  const lineHeight = 20;
  const maxHeight = maxLines * lineHeight;
  const [isExpanded, setIsExpanded] = useState(false);
  const [clampedText, isTextClamped, containerRef] = useClampLines(text, maxHeight);

  // Reset isExpanded state whenever text changes
  useEffect(() => {
    setIsExpanded(false);
  }, [text]);

  const textWithBreaks = transformText(text);
  const clampedTextWithBreaks = transformText(clampedText);

  function toggleExpansion(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    setIsExpanded(!isExpanded);
  }

  return (
    <div className="guidance-container">
      <div
        ref={containerRef}
        dangerouslySetInnerHTML={{ __html: !isExpanded && isTextClamped ? clampedTextWithBreaks : textWithBreaks }}
      />
      {isTextClamped && (
        <a href="#" onClick={toggleExpansion} className="show-more-link">
          {isExpanded ? "Show Less" : "Show More"}
        </a>
      )}
    </div>
  );
}

function useClampLines(text: string, maxHeight: number): [string, boolean, React.RefObject<HTMLDivElement>] {
  const [clampedText, setClampedText] = useState<string>(text);
  const [isTextClamped, setIsTextClamped] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;

      container.textContent = text;

      if (container.clientHeight > maxHeight) {
        let words = text.split(" ");
        while (container.clientHeight > maxHeight && words.length > 0) {
          words.pop();
          container.textContent = words.join(" ") + "...";
        }
      }

      setClampedText(container.textContent);
      setIsTextClamped(container.textContent !== text);
    }
  }, [text, maxHeight]);

  return [clampedText, isTextClamped, containerRef];
}

// Function used to transform text before rendering
function transformText(text: string): string {
  // Convert newlines to <br>
  let result = text.replace(/\n/g, "<br/>");

  // Convert "- item" to "<li>item</li>"
  const listItemRegex = /- (.+?)(?:<br\/>|$)/g;
  result = result.replace(listItemRegex, (_, item) => `<li class="rendered-text-list-item">${item}</li>`);

  // Wrap all consecutive <li> elements with <ul>
  const ulRegex = /(<li>.*?<\/li>)+/gs;
  result = result.replace(ulRegex, (matched) => `<ul class="rendered-text-list">${matched}</ul>`);

  return result;
}
export default PincitesExpandableText;
