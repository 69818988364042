import React, { useState, useCallback, ReactNode, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { createOrUpdateUser } from "../utils/apiUtils";
import useErrorHandling from "../error_handling/useErrorHandling";
import { logEvent } from "../logging/AzureApplicationInsights";
import { Maybe } from "../types/ClauseTypes";
import { logErrorForUnauthenticatedUser, tagSentryWithUserAndVersion } from "../logging/Sentry";

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<Maybe<string>>(getInitialToken());
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const { showErrorDialog } = useErrorHandling();

  const signIn = useCallback(async () => {
    setIsAuthenticating(true);
    const tokenResponse = await authenticate({ setToken, setIsAuthenticating });
    logEvent({ eventName: "plugin_login", token: tokenResponse });
    return tokenResponse;
  }, []);

  const logOut = useCallback(() => {
    // Clear token from state
    setToken(null);

    // Clear token from local storage
    // eslint-disable-next-line no-undef
    localStorage.removeItem("token");
    // eslint-disable-next-line no-undef
    localStorage.removeItem("tokenExpiry");
  }, []);

  useEffect(() => {
    if (token == null) {
      return;
    }
    // eslint-disable-next-line no-undef
    localStorage.setItem("token", token);
    const createOfFetchUser = async () => {
      await createOrUpdateUser({ token, showErrorDialog });
    };
    createOfFetchUser();
  }, [token, showErrorDialog]);

  const isAuthenticated = token != null;

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAuthenticating, token, signIn, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

// Authenticates the user using either Office authentication
// or if that does not work, the fallback authentication that
// hits the backend. Localhost will always use fallback auth
async function authenticate({
  setToken,
  setIsAuthenticating,
}: {
  setToken: (newToken: Maybe<string>) => void;
  setIsAuthenticating: (arg: boolean) => void;
}): Promise<string> {
  try {
    // eslint-disable-next-line no-undef
    const tokenResponse = await Office.auth.getAccessToken({
      allowSignInPrompt: true,
      allowConsentPrompt: true,
      forMSGraphAccess: true,
    });

    setToken(tokenResponse);
    // eslint-disable-next-line no-undef
    const { exp } = JSON.parse(atob(tokenResponse.split(".")[1]));
    // eslint-disable-next-line no-undef
    localStorage.setItem("tokenExpiry", exp.toString());

    setIsAuthenticating(false);
    await tagSentryWithUserAndVersion({ token: tokenResponse });

    return tokenResponse;
  } catch (error) {
    if (error instanceof Error) {
      // User clicked x on the auth prompt
      if ("code" in error && error.code === 13002) {
        setIsAuthenticating(false);
      }
      // If the user did not pass Microsoft authentication, we should log an error
      // 13007 indicates an account mismatch. For more information, see:
      // https://learn.microsoft.com/en-us/office/dev/add-ins/develop/troubleshoot-sso-in-office-add-ins#13007
      if ("code" in error && error.code !== 13007) {
        logErrorForUnauthenticatedUser({
          error,
          metadata: { origin: "Main authentication failed, reverting to fallback", errorCode: error.code },
        });
      }
    }

    // Fallback authentication
    const backendServiceSignInUrl = "https://api.pincites.com/api/users/oauth";

    try {
      const token: string = await new Promise((resolve, reject) => {
        // eslint-disable-next-line no-undef
        Office.context.ui.displayDialogAsync(
          backendServiceSignInUrl,
          { height: 70, width: 50 },
          function (asyncResult) {
            var dialog = asyncResult.value;

            // eslint-disable-next-line no-undef
            dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg) => {
              if ("message" in arg) {
                if (!isJWTValid(arg.message)) {
                  setIsAuthenticating(false);
                  reject(new Error("Invalid Authentication Token Format"));
                }
                setToken(arg.message);
                setIsAuthenticating(false);
                dialog.close();
                resolve(arg.message);
              } else {
                reject(new Error("No message received from dialog"));
              }
            });

            // eslint-disable-next-line no-undef
            dialog.addEventHandler(Office.EventType.DialogEventReceived, () => {
              setIsAuthenticating(false);
            });
          }
        );
      });

      return token;
    } catch (error) {
      setIsAuthenticating(false);
      const { showErrorDialog } = useErrorHandling();
      if (error instanceof Error) {
        const errorMsg = "Error while trying to sign in";
        showErrorDialog({ error, errorMsg });
        logErrorForUnauthenticatedUser({
          error,
          metadata: { origin: "Fallback and main authentication failed" },
        });
      }
      throw error;
    }
  }
}

function isJWTValid(token: string): boolean {
  // JWTs should have 3 sections: header, payload, and signature, separated by '.'.
  const parts = token.split(".");
  if (parts.length !== 3) {
    return false; // Not a valid JWT if it doesn't have 3 sections.
  }

  try {
    parts.forEach((part) => {
      // JWTs are base64url encoded, a URL-safe version of base64.
      // In base64url, '+' and '/' are respectively replaced by '-' and '_'.
      // Before decoding, we need to convert base64url back to base64.
      const base64 = part.replace(/-/g, "+").replace(/_/g, "/");

      // The window.atob() function decodes a base64 encoded string.
      // It will throw an error if the string is not a valid base64 encoded string.
      // If we can decode all parts without an error, it could be a valid JWT.
      // eslint-disable-next-line no-undef
      window.atob(base64);
    });
    return true; // If no errors were thrown, it's potentially a valid JWT.
  } catch (_e) {
    return false; // If an error was thrown, it's not a valid JWT.
  }
}

function getInitialToken(): Maybe<string> {
  // Check that window and localStorage both exist
  // eslint-disable-next-line no-undef
  if (typeof window !== "undefined" && window.localStorage) {
    // eslint-disable-next-line no-undef
    const storedToken = window.localStorage.getItem("token");
    // eslint-disable-next-line no-undef
    const storedTokenExpiry = window.localStorage.getItem("tokenExpiry");

    // Check if storedToken or storedTokenExpiry is undefined, null, or 'undefined'
    if (
      storedToken === undefined ||
      storedToken === null ||
      storedToken === "undefined" ||
      storedToken === "null" ||
      storedTokenExpiry === undefined ||
      storedTokenExpiry === null ||
      storedTokenExpiry === "undefined" ||
      storedTokenExpiry === "null"
    ) {
      return null;
    } else {
      // Check if token is expired
      const currentUnixTime = Math.floor(new Date().getTime() / 1000);
      if (currentUnixTime >= parseInt(storedTokenExpiry)) {
        // Token has expired
        // eslint-disable-next-line no-undef
        window.localStorage.removeItem("token");
        // eslint-disable-next-line no-undef
        window.localStorage.removeItem("tokenExpiry");
        return null;
      }
      return storedToken;
    }
  }

  // If window or localStorage doesn't exist, return null
  return null;
}
