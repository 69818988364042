import React from "react";
import { Maybe, TClauseTemplate, TMatchedClause } from "../types/ClauseTypes";
import { Text, Stack } from "@fluentui/react";
import {
  TClauseMatchingType,
  getBadgeTypeFromMatchingType,
  getLabelForMatchingTypeAndInclusionRequirement,
} from "../types/ClauseMatching";
import { scrollToClauseInDocument } from "../utils/wordUtils";
import { InclusionRequirement } from "../types/InclusionRequirement";
import PincitesBadge, { PincitesBadgeType } from "../library/PincitesBadge";
import useClause from "../context/clause_context/useClause";

interface ClauseLinkProps {
  clauseMatchingType: TClauseMatchingType;
  clause: TClauseTemplate;
  matchedClause: Maybe<TMatchedClause>;
}

function ClauseLink({ clauseMatchingType, clause, matchedClause }: ClauseLinkProps): React.JSX.Element {
  const { setClauseResult } = useClause();
  if (!getShouldShowClauseLink(clauseMatchingType, clause.inclusionRequirement)) {
    return <></>;
  }
  const shouldShowBadge = getShouldShowClauseBadge(clauseMatchingType, clause.inclusionRequirement);
  const badgeType = getClauseLinkBadgeType(clauseMatchingType, clause.inclusionRequirement);
  const matchedClauseTypeExistsInDocument = getDoesMatchedClauseExistForMatchingType(clauseMatchingType, matchedClause);

  const handleClick = () => {
    let matchedClauseToSet: Maybe<TMatchedClause> = null;
    if (matchedClauseTypeExistsInDocument) {
      matchedClauseToSet = matchedClause;
    }
    setClauseResult({ selectedClause: clause, matchedClause: matchedClauseToSet });
    if (matchedClauseToSet != null) {
      scrollToClauseInDocument(matchedClauseToSet);
    }
  };

  return (
    <Stack horizontal>
      <Text onClick={handleClick} variant="medium" block className="clause-link">
        {clause.name}
      </Text>
      {shouldShowBadge && (
        <PincitesBadge
          badgeType={badgeType}
          label={getLabelForMatchingTypeAndInclusionRequirement(clauseMatchingType, clause.inclusionRequirement)}
        />
      )}
    </Stack>
  );
}

function getClauseLinkBadgeType(
  clauseMatchingType: TClauseMatchingType,
  inclusionRequirement: InclusionRequirement
): PincitesBadgeType {
  const overrideBadgeType = getOverrideBadgeTypeForClauseLink(inclusionRequirement);
  if (overrideBadgeType != null) {
    return overrideBadgeType;
  }
  return getBadgeTypeFromMatchingType(clauseMatchingType);
}

function getOverrideBadgeTypeForClauseLink(inclusionRequirement: InclusionRequirement): Maybe<PincitesBadgeType> {
  switch (inclusionRequirement) {
    case InclusionRequirement.PROHIBITED:
      return PincitesBadgeType.DANGER;
    case InclusionRequirement.REQUIRED:
    case InclusionRequirement.OPTIONAL:
      return null;
  }
}

function getShouldShowClauseBadge(
  clauseMatchingType: TClauseMatchingType,
  inclusionRequirement: InclusionRequirement
): boolean {
  switch (clauseMatchingType) {
    case TClauseMatchingType.CHANGED:
    case TClauseMatchingType.MISSING:
      return true;
    case TClauseMatchingType.STANDARD:
      return getShouldShowClauseBadgeForStandardText(inclusionRequirement);
  }
}

function getShouldShowClauseBadgeForStandardText(inclusionRequirement: InclusionRequirement): boolean {
  switch (inclusionRequirement) {
    case InclusionRequirement.PROHIBITED:
      return true;
    case InclusionRequirement.REQUIRED:
    case InclusionRequirement.OPTIONAL:
      return false;
  }
}

function getShouldShowClauseLink(
  clauseMatchingType: TClauseMatchingType,
  inclusionRequirement: InclusionRequirement
): boolean {
  switch (clauseMatchingType) {
    case TClauseMatchingType.CHANGED:
    case TClauseMatchingType.STANDARD:
      return true;
    case TClauseMatchingType.MISSING:
      return getShouldShowClauseLinkWhenClauseIsMissing(inclusionRequirement);
  }
}

function getShouldShowClauseLinkWhenClauseIsMissing(inclusionRequirement: InclusionRequirement): boolean {
  switch (inclusionRequirement) {
    case InclusionRequirement.PROHIBITED:
    case InclusionRequirement.REQUIRED:
      return true;
    case InclusionRequirement.OPTIONAL:
      return false;
  }
}

function getDoesMatchedClauseExistForMatchingType(
  clauseMatchingType: TClauseMatchingType,
  matchedClause: Maybe<TMatchedClause>
): boolean {
  switch (clauseMatchingType) {
    case TClauseMatchingType.CHANGED:
      return true;
    case TClauseMatchingType.STANDARD:
      return matchedClause != null;
    case TClauseMatchingType.MISSING:
      return false;
  }
}

export default ClauseLink;
