import { useContext } from "react";
import FeedbackContext from "./FeedbackContext";

const useFeedbackModal = () => {
  const context = useContext(FeedbackContext);
  if (context === undefined) {
    throw new Error("useFeedbackModal must be used within an FeedbackProvider");
  }
  return context;
};

export default useFeedbackModal;
